@keyframes page {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes webpageHome1 {
  0% {
    opacity: 0;
    transform: translateX(-20rem);
  }

  15% {
    opacity: 0;
    transform: translateX(-20rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes webpageHome2 {
  0% {
    opacity: 0;
    transform: translateX(-20rem);
  }

  25% {
    opacity: 0;
    transform: translateX(-20rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes webpageHome3 {
  0% {
    opacity: 0;
    transform: translateX(-20rem);
  }

  35% {
    opacity: 0;
    transform: translateX(-20rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes circle-svg {
  0% {
    stroke-dashoffset: 85px;
  }

  100% {
    stroke-dashoffset: -85px;
  }
}

@keyframes rotate-svg {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes toNormal {
  0% {
    stroke-dashoffset: 100px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes cursor {
  0% {
    background-color: #0000;
  }

  50% {
    background-color: #0000;
  }

  51% {
    background-color: var(--color-black-light);
  }
}

.background {
  background-color: var(--color-white);
  padding: var(--section-space) 0;
  margin-top: var(--section-space);
  border-radius: 5rem;
}

.background--dark {
  background-color: var(--color-white-dark);
}

.max-content-width {
  max-width: 140rem;
  margin: auto;
  padding-left: 5rem;
  padding-right: 5rem;
}

@media only screen and (max-width: 34.375em) {
  .max-content-width {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 24em) {
  .max-content-width {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

body .center {
  text-align: center;
}

.a-button {
  width: 100%;
  outline: none;
  flex-direction: column;
  text-decoration: none;
  display: flex;
}

.break:after {
  content: "";
  display: block;
}

body .cursor:after {
  content: "";
  height: 100%;
  width: .4rem;
  z-index: 10;
  background-color: var(--color-black-light);
  border-radius: .2rem;
  display: block;
  position: absolute;
  top: 0;
  right: -.3rem;
}

.cursor--flash:after {
  content: "";
  height: 100%;
  width: .4rem;
  z-index: 10;
  background-color: var(--color-black-light);
  border-radius: .2rem;
  animation: cursor .9s linear infinite;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -.3rem;
}

.hide {
  opacity: .2;
}

.display-none {
  display: none;
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  --color-primary-dark: #d8cb00;
  --color-primary: #fff600;
  --color-primary-light: #fff724;
  --color-secondary: #0a4bff;
  --color-secondary-dark: #0b0edf;
  --color-black-dark: #2a2a2a;
  --color-black: #3b3b3b;
  --color-black-light: #484848;
  --color-gray-dark: #707070;
  --color-gray: #d3d3d3;
  --color-gray-light: #e1e1e1;
  --color-white-dark: #f0f0f0;
  --color-white: #f9f9f9;
  --color-pure-white: #fff;
  --color-primary-opacity-05: #fdff90;
  --color-secondary-card-dark: #2c6ecb;
  --color-secondary-card-light: #69a3ff;
  --color-white-opacity-05: #ffffff9a;
  --color-white-opacity-03: #fff5;
  --color-gray-opacity-02: #d3d3d323;
  --section-space: 14rem;
  --shadow-big: 0 1rem 4rem #2a2a2a4d;
  --shadow: 0 0 2rem #2a2a2a33;
  --shadow-small: 0 0 .5rem #2a2a2a66;
  --shadow-light: 0 0 2rem #2a2a2a1a;
  --color-green: #18cc24;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (max-width: 78.125em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 24em) {
  html {
    font-size: 48%;
  }
}

body {
  box-sizing: border-box;
  scroll-behavior: smooth;
  color: var(--color-black-dark);
  background-color: var(--color-pure-white);
  font-family: Dosis, sans-serif;
  animation: page 1s;
}

@media only screen and (max-width: 34.375em) {
  body {
    --section-space: 10rem;
  }
}

.body--dark-mode {
  --color-primary-dark: #d8cb00;
  --color-primary: #262cd8;
  --color-primary-light: #2c30a0;
  --color-secondary: #d1a426;
  --color-secondary-dark: #0b0edf;
  --color-black-dark: #ccc;
  --color-black: #b4b4b4;
  --color-black-light: #a6a6a6;
  --color-gray-dark: #707070;
  --color-gray: #454545;
  --color-gray-light: #3f3f3f;
  --color-white-dark: #303030;
  --color-white: #2a2a2a;
  --color-pure-white: #272727;
  --color-primary-opacity-05: #272673;
  --color-secondary-card-dark: #b78948;
  --color-secondary-card-light: #926828;
  --shadow-big: 0 1rem 4rem #00000080;
  --shadow: 0 0 2rem #00000080;
  --shadow-small: 0 0 .5rem #0006;
  --shadow-light: 0 0 2rem #0000004d;
  background-color: #212121;
}

::selection {
  background-color: var(--color-secondary);
  color: var(--color-white);
}

.plus {
  height: 3.5rem;
  width: 3.5rem;
  background-color: var(--color-white-opacity-03);
  border-radius: 10rem;
  align-items: center;
  padding: .5rem;
  display: grid;
}

.plus__horizontal {
  width: 100%;
  height: .4rem;
  background-color: var(--color-white);
  border-radius: 2rem;
  grid-area: 1 / 1 / 2 / 2;
  transition: all .2s;
}

@media (prefers-reduced-motion) {
  .plus__horizontal {
    transition: none;
  }
}

.plus__vertical {
  width: 100%;
  height: .4rem;
  background-color: var(--color-gray);
  border-radius: 2rem;
  grid-area: 1 / 1 / 2 / 2;
  transition: all .2s;
  transform: rotate(90deg);
}

@media (prefers-reduced-motion) {
  .plus__vertical {
    transition: none;
  }
}

.button {
  cursor: pointer;
  text-align: center;
  background-color: var(--color-secondary);
  letter-spacing: .1rem;
  box-shadow: var(--shadow);
  color: var(--color-gray-light);
  z-index: 20;
  border: none;
  border-radius: 5rem;
  outline: none;
  align-self: stretch;
  margin-top: auto;
  margin-left: 5rem;
  margin-right: 5rem;
  padding: 2rem;
  font-family: Dosis, sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  transition: all .2s;
  position: relative;
  overflow: hidden;
}

.button > * {
  z-index: 2000;
  position: relative;
}

@media (prefers-reduced-motion) {
  .button {
    transition: none;
    transform: scale(1) !important;
  }
}

.button:hover {
  color: var(--color-white);
  transform: scale(1.01);
}

.button:active, .button:focus {
  outline: none;
  transform: scale(.98);
}

.button:before {
  content: "";
  height: 7rem;
  width: 7rem;
  opacity: 0;
  background-image: radial-gradient(#ffffff32, #fff1);
  border-radius: 10rem;
  transition: all .2s;
  display: block;
  position: absolute;
  top: 0%;
  right: calc(50% - 3.5rem);
  transform: scale(1);
}

.button:after {
  content: "";
  height: 370%;
  width: 110%;
  background-image: radial-gradient(#fff2, #0000);
  border-radius: 50%;
  transition: all .4s;
  display: block;
  position: absolute;
  top: -150%;
  right: -110%;
}

.button:hover:after {
  transform: translate(-100%, 1rem);
}

@media (prefers-reduced-motion) {
  .button:hover:after {
    transform: none;
  }
}

.button:active svg {
  transform: rotate(90deg)translateX(.5rem);
}

.button:active:before {
  opacity: 1;
  transform: scale(5);
}

@media (prefers-reduced-motion) {
  .button:active:before {
    opacity: 0;
    transform: none;
  }
}

.button--inline:link, .button--inline:visited, .button--inline:hover, .button--inline:active {
  color: var(--color-secondary);
}

.button--gray-large {
  color: var(--color-black);
  background-color: var(--color-gray-light);
  padding: 2.7rem;
  font-size: 2.4rem;
}

.button--gray-large:hover {
  color: var(--color-black-dark);
}

.button--gray-large:after {
  background-image: radial-gradient(#fff5, #fff1);
}

.body--dark-mode .button--gray-large:after, .body--dark-mode .button--gray-large:before {
  background-image: radial-gradient(#ffffff13, #ffffff01);
}

.button--down-arrow {
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
}

.button--down-arrow > :not(svg) {
  padding: 0 1rem;
}

.button--down-arrow svg {
  z-index: 200;
  width: 3rem;
  height: 2.3rem;
  transition: all .2s;
  position: relative;
  transform: rotate(90deg);
}

.button--underline {
  box-shadow: none;
  color: var(--color-black);
  background-color: #0000;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  text-decoration: none;
  overflow: visible;
}

.button--underline span {
  height: .5rem;
  width: 100%;
  background-color: var(--color-gray);
  border-radius: 2.7rem;
  margin-top: .3rem;
  transition: all .2s;
  display: block;
}

.button--underline:before, .button--underline:after {
  display: none;
}

.button--underline:hover {
  color: var(--color-black-dark);
}

.button--underline:hover span {
  transform: translateY(.1rem);
}

.button--narrow {
  padding: 1.3rem 2rem;
  font-size: 1.7rem;
}

.button--plus {
  align-items: center;
  padding: .8rem 2rem;
  font-size: 1.8rem;
  display: flex;
}

.button--plus span {
  transform: translateX(.9rem);
}

.button--no-margin {
  margin: 0;
}

.button--outline {
  color: var(--color-black-light);
  border: solid .3rem var(--color-gray);
  box-shadow: none;
  background-color: #0000;
  padding: 1rem 2rem;
  font-weight: 500;
}

.button--outline:after, .button--outline:before {
  display: none;
}

.button--outline:hover {
  color: var(--color-black);
  filter: drop-shadow(var(--shadow) );
}

.button--x {
  width: 4.5rem;
  height: 4.5rem;
  padding: 0;
  display: block;
}

.button--x:active svg {
  transform: none;
}

.button--hidden {
  cursor: not-allowed;
  opacity: .3;
  visibility: hidden;
}

.button--hidden:hover, .button--hidden:active {
  transform: none;
}

.info {
  background-color: var(--color-gray-opacity-02);
  grid-gap: 5rem;
  border-radius: 2.7rem;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  margin-top: 8rem;
  padding: 7rem;
  transition: all .2s;
  display: grid;
  position: relative;
}

@media only screen and (max-width: 34.375em) {
  .info {
    background-color: #0000;
    border-radius: .9rem;
    margin-top: 5rem;
    padding: 3rem;
  }
}

@media only screen and (max-width: 24em) {
  .info {
    padding: 0 2rem;
  }
}

.info__box {
  grid-column-gap: 1.5rem;
  grid-template-rows: [row-1] 4.5rem[row-2] 1fr[row-3];
  grid-template-columns: 4.5rem[col-2] 1fr[col-3];
  align-items: center;
  display: grid;
}

.info__svg {
  width: 100%;
  height: 100%;
  fill: var(--color-secondary);
  padding: .3rem;
  display: block;
}

.info__heading {
  grid-area: row-1 / col-2 / row-2 / col-3;
  justify-self: start;
}

@media only screen and (max-width: 34.375em) {
  .info__heading {
    justify-self: unset;
  }
}

.info__text {
  grid-column: col-2 / col-3;
  align-self: start;
}

@media only screen and (max-width: 34.375em) {
  .info--center {
    text-align: center;
  }
}

@media only screen and (max-width: 34.375em) {
  .info--center .info__box {
    grid-template-rows: [row-0] 5rem[row-1] 5rem[row-2] 1fr;
    grid-template-columns: [col-2] 1fr[col-3];
  }
}

.to-top {
  z-index: 10000;
  opacity: 0;
  background-color: #0000;
  border: none;
  transition: all .2s cubic-bezier(.41, 1.47, .9, 1.02) .3s;
  position: fixed;
  bottom: 1.7%;
  right: 1%;
  transform: scale(0);
}

@media only screen and (max-width: 54.37em) {
  .to-top {
    bottom: 1.5rem;
    right: 1.5rem;
  }
}

.to-top__arrow {
  box-shadow: var(--shadow-light);
  background-image: linear-gradient(to right bottom, var(--color-secondary-card-dark), var(--color-secondary) );
  width: 7rem;
  height: 7rem;
  border-radius: 5rem;
  transition: all .3s;
}

@media only screen and (max-width: 54.37em) {
  .to-top__arrow {
    width: 5.7rem;
    height: 5.7rem;
  }
}

.to-top__arrow svg {
  width: 100%;
  height: 100%;
  filter: brightness(0%) invert();
  padding: 1.5rem;
  transition: all .2s;
  transform: rotate(-90deg);
}

.to-top__arrow:hover {
  background-color: var(--color-gray);
  transform: scale(1.03);
}

@media (prefers-reduced-motion) {
  .to-top__arrow:hover {
    transform: none;
  }
}

.to-top:active .to-top__arrow svg {
  transform: rotate(-90deg)translateX(.3rem);
}

@media (prefers-reduced-motion) {
  .to-top:active .to-top__arrow svg {
    transform: rotate(-90deg);
  }
}

.to-top--active {
  opacity: 1;
  transform: scale(1);
}

.cards {
  padding-bottom: var(--section-space);
}

.cards__container {
  grid-column-gap: 7rem;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  margin-top: 7rem;
  font-family: Open Sans, sans-serif;
  display: grid;
}

.cards__card {
  background-color: var(--color-pure-white);
  box-shadow: var(--shadow);
  border-radius: 2.7rem;
  flex-direction: column;
  align-items: center;
  padding: 3.7rem 4.1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.body--dark-mode .cards__card-night-mode-hide {
  display: none;
}

.cards__card-paragraph {
  text-align: center;
  margin-bottom: auto;
  padding: 1.8rem 0 4rem;
  font-size: 1.7rem;
  line-height: 1.5;
}

.cards__card-night-mode {
  display: none;
}

.body--dark-mode .cards__card-night-mode {
  display: block;
}

.cards__card > svg {
  width: 9rem;
  height: 9rem;
  stroke: #444343;
  stroke-width: 0;
  fill: #444343;
  stroke-dasharray: 100;
  stroke-dashoffset: 100px;
  margin-bottom: 1.8rem;
}

.body--dark-mode .cards__card > svg {
  filter: invert(80%);
}

.cards .cards__svg--active {
  animation: toNormal 2s forwards;
}

@media only screen and (max-width: 88em) {
  .cards {
    padding-bottom: var(--section-space);
  }

  .cards__container {
    grid-gap: 5rem;
  }

  .cards svg {
    width: 7rem;
    height: 7rem;
  }
}

@media only screen and (max-width: 34.375em) {
  .cards__container {
    margin-top: 5rem;
  }
}

.wide-card {
  margin: var(--section-space) auto 0;
}

.wide-card__card {
  background-image: linear-gradient(to bottom right, var(--color-secondary-card-dark), var(--color-secondary-card-light) );
  border-radius: 5rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 7rem;
  padding: 11rem;
  display: grid;
}

.wide-card__card a {
  justify-content: end;
  justify-self: end;
  text-decoration: none;
  display: flex;
}

.wide-card__card a > * {
  align-self: center;
  margin: 0;
  padding-left: 7rem;
  padding-right: 7rem;
}

.wide-card .wide-card__text {
  color: var(--color-pure-white);
}

@media only screen and (max-width: 88em) {
  .wide-card__card {
    padding: 10rem 8rem;
  }

  .wide-card__card a {
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 50em) {
  .wide-card__card {
    grid-gap: 3.5rem;
    grid-template-columns: 1fr;
  }

  .wide-card__card a {
    justify-self: center;
    padding-top: 1rem;
    padding-left: 0;
  }
}

@media only screen and (max-width: 34.375em) {
  .wide-card__card {
    margin-top: 5rem;
    padding: 7rem 3rem;
  }
}

.tile-title {
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  display: flex;
}

@media only screen and (max-width: 34.375em) {
  .tile-title {
    padding-top: 3rem;
  }
}

.tile-title__text {
  background-color: var(--color-white-dark);
  border-radius: .9rem .9rem 0 0;
  padding: 1.5rem 2.5rem;
}

.tile-title__line {
  width: 100%;
  height: 1rem;
  background-color: var(--color-white-dark);
  border-radius: 5rem;
  margin-bottom: 2rem;
  position: relative;
  top: -1rem;
}

.tiles {
  grid-gap: 5rem;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  display: grid;
}

.tiles__tile {
  box-shadow: var(--shadow);
  border-radius: .9rem;
  grid-template-rows: 20rem min-content max-content 1fr max-content;
  display: grid;
  overflow: hidden;
}

.tiles__img {
  filter: brightness(90%);
  display: grid;
  overflow: hidden;
}

.tiles__img img {
  width: 100%;
  filter: blur(.15rem) brightness(80%);
}

.tiles__heading {
  background-color: var(--color-primary-light);
  filter: brightness(95%);
  box-shadow: var(--shadow);
  border-radius: 2rem;
  justify-self: center;
  padding: 1.6rem 3.5rem;
  transform: translateY(-50%);
}

.tiles__list {
  margin: 0 4rem;
  font-family: Open Sans, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  list-style-image: url("list-svg.56c97576.svg");
}

.tiles__list li:not(:last-child) {
  padding-bottom: 1rem;
}

.tiles__tags {
  grid-gap: 3rem;
  grid-template-columns: repeat(2, max-content);
  align-self: end;
  margin: 1.1rem 0 0 2.58rem;
  display: grid;
}

.tiles__tag {
  align-items: center;
  display: flex;
}

.tiles__tag-svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: var(--color-green);
  display: block;
}

.tiles__tag-name {
  color: var(--color-black-light);
  text-transform: uppercase;
  padding-left: .4rem;
  font-family: Open Sans, sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
}

.tiles__buttons {
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  align-self: end;
  align-items: end;
  margin-top: 2rem;
  padding: 2rem;
  display: grid;
}

.wide-tiles {
  width: 100%;
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
  align-items: start;
  display: grid;
}

@media only screen and (max-width: 34.375em) {
  .wide-tiles {
    grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr));
  }
}

.wide-tiles__container {
  flex-direction: column;
  display: flex;
}

.wide-tiles__tile {
  background-color: var(--color-pure-white);
  box-shadow: var(--shadow);
  border-radius: .9rem;
  padding: 2rem;
}

.wide-tiles__tile:not(:first-child) {
  margin-top: 4rem;
}

.wide-tiles__header {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.wide-tiles__img {
  width: 4.2rem;
  height: auto;
  flex: 0 0 4.2rem;
  margin-right: 1rem;
  display: block;
}

.body--dark-mode .wide-tiles__img {
  filter: brightness(150%) saturate(200%);
}

.wide-tiles__info {
  grid-row-gap: 1rem;
  padding: 2rem 1rem;
  display: grid;
}

.wide-tiles__date, .wide-tiles__duration {
  align-items: center;
  display: flex;
}

.wide-tiles__date p, .wide-tiles__duration p {
  color: var(--color-black);
  padding-left: 1.8rem;
  font-family: Open Sans, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}

.wide-tiles__date svg, .wide-tiles__duration svg {
  width: 2.2rem;
  height: 2.2rem;
  fill: var(--color-secondary);
  stroke: var(--color-secondary);
}

.wide-tiles__duration svg {
  width: 2.1rem;
  height: 2.1rem;
}

.wide-tiles__buttons {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.wide-tiles__buttons .button {
  align-self: flex-end;
}

@media only screen and (max-width: 34.375em) {
  .wide-tiles__buttons {
    flex-direction: column;
  }

  .wide-tiles__buttons .button {
    align-self: start;
  }

  .wide-tiles__buttons > :last-child {
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    margin-top: 2rem;
  }

  .wide-tiles__buttons .button--hidden {
    display: none;
  }
}

.wide-tiles__open .plus__horizontal {
  transform: rotate(180deg);
}

.wide-tiles__open .plus__vertical {
  transform: rotate(360deg);
}

.wide-tiles__sub {
  margin-top: 2rem;
}

.wide-tiles__sub-tile {
  background-color: var(--color-white);
  border-radius: .9rem;
  grid-template-rows: 20rem;
  grid-template-columns: [col-1] 1rem[col-2] 3fr[col-3] 4fr[col-4];
  grid-auto-rows: 0;
  display: grid;
  overflow: hidden;
}

.wide-tiles__sub-tile:not(:last-child) {
  margin-bottom: 2rem;
}

.wide-tiles__sub-line {
  height: 20rem;
  width: 1rem;
  background-color: var(--color-gray);
}

.wide-tiles__sub-img {
  border-radius: .9rem;
  grid-area: 1 / col-2 / 2 / col-3;
  margin: 1.3rem;
  display: block;
  overflow: hidden;
}

.wide-tiles__sub-img img {
  width: 100%;
  filter: blur(.3rem) brightness(70%);
}

.body--dark-mode .wide-tiles__sub-img img {
  filter: blur(.4rem) brightness(110%);
}

.wide-tiles__sub-heading {
  z-index: 2;
  grid-area: 1 / col-2 / 2 / col-3;
  justify-content: center;
  align-items: center;
  display: grid;
  position: relative;
}

.wide-tiles__sub-heading > * {
  z-index: 20;
  color: var(--color-pure-white);
  text-shadow: 0 0 1.5rem var(--color-black);
}

.body--dark-mode .wide-tiles__sub-heading > * {
  color: #111;
  text-shadow: 0 0 1.5rem var(--color-black-dark);
}

.wide-tiles__sub-description {
  flex-direction: column;
  justify-content: space-between;
  padding: 1.3rem 3rem;
  display: flex;
}

.wide-tiles__sub-description h6 {
  padding-bottom: 1rem;
}

.map {
  width: 100%;
  height: 100vh;
  background-color: var(--color-pure-white);
  z-index: 50000;
  opacity: 0;
  visibility: hidden;
  padding: 2rem;
  transition: all .3s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(.4);
}

@supports (backdrop-filter: blur(2rem)) {
  .map {
    -webkit-backdrop-filter: blur(.5rem);
    backdrop-filter: blur(.5rem);
    background-color: #0000;
  }
}

@media only screen and (max-width: 29em) {
  .map {
    padding: .5rem;
  }
}

.map__button {
  width: 5.8rem;
  height: 5.8rem;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  display: grid;
  position: fixed;
  top: 4.7rem;
  right: 3.1rem;
}

@media only screen and (max-width: 29em) {
  .map__button {
    top: 3.4rem;
    right: 2.2rem;
  }
}

.map__button svg {
  width: 4.5rem;
  height: 4.5rem;
  display: block;
}

.map iframe {
  background-color: var(--color-pure-white);
  width: 100%;
  height: 100%;
  border: 1.2rem solid var(--color-black);
  border-radius: .9rem;
}

@media only screen and (max-width: 29em) {
  .map iframe {
    border-width: .8rem;
  }
}

.map--active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.previewer {
  background-color: var(--color-pure-white);
  width: 100%;
  height: 100vh;
  z-index: 50000;
  opacity: 0;
  visibility: hidden;
  grid-template-rows: 5.7rem 1fr;
  grid-template-columns: 1fr;
  justify-items: center;
  transition: all .3s;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  transform: scale(.4);
}

.previewer__heading {
  width: 100%;
  height: 5.7rem;
  box-shadow: var(--shadow);
  background-color: var(--color-pure-white);
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
}

@media only screen and (max-width: 54.37em) {
  .previewer__heading {
    grid-template-columns: 1fr 1fr;
  }
}

.previewer__title {
  padding-left: 2rem;
  font-size: 2rem;
  font-weight: 600;
}

.previewer__zooming {
  width: 100%;
  justify-content: center;
  justify-self: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 54.37em) {
  .previewer__zooming {
    display: none;
  }
}

.previewer__zooming-in, .previewer__zooming-out {
  color: var(--color-secondary);
  background-color: #0000;
  border: none;
  border-radius: 5rem;
  margin: 0 1.3rem;
  padding: .5rem;
  display: inline-block;
}

.previewer__zooming-in svg, .previewer__zooming-out svg {
  width: 2.3rem;
  height: 2.3rem;
  margin: .5rem;
  display: block;
}

.previewer__zooming-in:hover, .previewer__zooming-out:hover {
  background-color: var(--color-white-dark);
}

.previewer__zooming-in:active svg, .previewer__zooming-out:active svg {
  transform: scale(.95);
}

.previewer__zoom-level {
  text-align: center;
  height: 3rem;
  width: 8rem;
  z-index: 500;
  letter-spacing: .1rem;
  background-color: #e1e1e14d;
  border: .3rem solid #0000;
  border-radius: .7rem;
  outline: none;
  padding: .7rem .7rem .7rem .14rem;
  font-family: inherit;
  font-size: 1.7rem;
  font-weight: 500;
  position: relative;
}

.previewer__zoom-level:focus {
  outline: .3rem solid var(--color-gray);
  outline-offset: .3rem;
}

.previewer__zoom-level-percent {
  z-index: 200;
  font-size: 1.6rem;
  font-weight: 600;
  position: absolute;
  left: 52.3%;
}

.previewer__close {
  justify-content: center;
  place-self: center end;
  align-items: center;
  margin: 0 2rem;
  display: grid;
  position: relative;
}

.previewer__close svg {
  width: 3.5rem;
  height: 3.5rem;
  display: block;
}

.previewer__holder {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  z-index: -1;
  justify-content: center;
  justify-items: center;
  padding-bottom: 5rem;
  display: grid;
  overflow-y: scroll;
}

.previewer__zoomer {
  transition: all .2s;
}

.previewer__img {
  width: 100%;
  box-shadow: var(--shadow-big);
  margin-top: 3rem;
  display: block;
}

.previewer--active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.tool-tip {
  z-index: 500000;
  letter-spacing: .1rem;
  color: var(--color-white);
  background-color: var(--color-black);
  box-shadow: var(--shadow-small);
  opacity: 0;
  visibility: hidden;
  border-radius: .3rem;
  padding: 1.1rem 1.9rem;
  font-family: Dosis, sans-serif;
  font-size: 1.7rem;
  transition: all .1s;
  position: fixed;
  top: 1rem;
  left: 1rem;
  transform: translateY(.2rem);
}

.tool-tip:before {
  content: "";
  width: 1rem;
  height: 1rem;
  border: 1rem solid #0000;
  border-bottom: 1rem solid var(--color-black);
  z-index: 20000;
  clip-path: circle(10px at 50% 103%);
  display: block;
  position: absolute;
  top: -1.9rem;
  left: calc(50% - 1rem);
}

.tool-tip--side-left:before {
  border: 1rem solid #0000;
  border-left: 1rem solid var(--color-black);
  clip-path: circle(10px at -3%);
  top: calc(50% - 1rem);
  left: 100%;
}

.tool-tip--side-right:before {
  z-index: 20000;
  border: 1rem solid #0000;
  border-right: 1rem solid var(--color-black);
  clip-path: circle(10px at 97%);
  top: calc(50% - 1rem);
  left: -1.9rem;
}

.tool-tip--bottom:before {
  border: 1rem solid #0000;
  border-top: 1rem solid var(--color-black);
  clip-path: circle(10px at 50% -3%);
  top: calc(100% - .1rem);
  left: calc(50% - 1rem);
}

.tool-tip--active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.navigation__logo-box, .navigation__link-box {
  background-color: var(--color-pure-white);
  border-radius: 2.7rem;
}

.navigation {
  width: 100%;
  height: 10rem;
  filter: drop-shadow(var(--shadow) );
  z-index: 20000;
  justify-content: space-between;
  align-items: stretch;
  margin: 2rem 0;
  padding: 0 2rem;
  transition: all .3s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.navigation__logo-box {
  flex: 0 10rem;
}

.navigation__logo-box a:link, .navigation__logo-box a:visited {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.navigation__logo-box svg {
  width: 50%;
  height: 50%;
  filter: drop-shadow(var(--shadow-small) ) drop-shadow(var(--shadow) );
  transition: all .2s;
  display: block;
}

.navigation__logo-box:hover svg {
  transform: scale(1.03);
}

.navigation__logo-box a:active, .navigation__logo-box a:focus svg {
  transform: scale(.97);
}

.navigation__link-box {
  padding: .8rem 2rem;
  position: relative;
}

.navigation__links {
  height: 100%;
  justify-content: space-around;
  align-items: stretch;
  list-style: none;
  display: flex;
}

.navigation__links a:link, .navigation__links a:visited {
  height: 100%;
  color: var(--color-secondary);
  z-index: 4;
  outline: none;
  align-items: center;
  padding: 0 3.5rem;
  font-size: 2.3rem;
  line-height: 1;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
}

.navigation__links a:focus, .navigation__links a:active {
  outline: none;
  transform: scale(.97);
}

.navigation__links li {
  position: relative;
}

.navigation__links li:not(:last-of-type):before {
  content: "";
  background-color: var(--color-white);
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: .2;
  border-radius: 2.7rem;
  transition: all .3s cubic-bezier(.41, 1.47, .9, 1.02);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

@media (prefers-reduced-motion) {
  .navigation__links li:not(:last-of-type):before {
    transition: all;
  }
}

.navigation__links li:hover:before {
  opacity: 1;
  transform: scale(1);
}

.navigation__links button {
  cursor: pointer;
  height: 100%;
  z-index: 2;
  background: none;
  border: none;
  outline: none;
  position: relative;
}

.navigation__links button:hover svg {
  animation: circle-svg 4s linear infinite;
}

@media (prefers-reduced-motion) {
  .navigation__links button:hover svg {
    animation: none;
  }
}

.body--dark-mode .navigation__links button:hover svg {
  animation: rotate-svg 32s linear infinite;
}

@media (prefers-reduced-motion) {
  .body--dark-mode .navigation__links button:hover svg {
    animation: none;
  }
}

.navigation__links svg {
  width: 4.3rem;
  height: 4.3rem;
  stroke-width: 3px;
  fill: var(--color-black-light);
  stroke: #0270ff5b;
  stroke-dasharray: 85;
  stroke-dashoffset: 85px;
  margin: 0 3rem;
  transition: all .2s;
  display: block;
  transform: scale(.97);
}

.body--dark-mode .navigation__links svg {
  stroke: #ffd90231;
  fill: var(--color-secondary);
}

.navigation__links button:hover svg {
  filter: brightness(90%) drop-shadow(var(--shadow) );
  transform: scale(1);
}

.navigation__links button:active svg {
  filter: brightness(90%) drop-shadow(var(--shadow) );
  transform: scale(.95);
}

.navigation__current:after {
  z-index: 3;
  content: "";
  height: .7rem;
  width: 70%;
  background-color: var(--color-secondary);
  border-radius: .9rem;
  margin: 0 15%;
  transition: all .2s;
  display: block;
  position: absolute;
  bottom: .8rem;
  left: 0;
}

.navigation--active {
  background-color: var(--color-pure-white);
  height: 7rem;
  margin: 0;
  padding: 0;
}

.navigation--active .navigation__logo-box svg {
  width: 59%;
  height: 59%;
}

.navigation--active .navigation__link-box {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.navigation--active .navigation__link-box svg {
  width: 3rem;
  margin: 0 2rem;
}

.navigation--active .navigation__current:after {
  border-radius: .5rem .5rem 0 0;
  bottom: -.2rem;
}

.navigation--active .navigation__link-box li:hover:before {
  transform: scale(.87);
}

.navigation__phone {
  display: none;
}

@media only screen and (max-width: 88em) {
  .navigation {
    background-color: var(--color-pure-white);
    height: 7rem;
    align-items: center;
    margin: 0;
    padding: 2rem;
    overflow: visible;
  }

  .navigation__logo-box {
    background-color: #0000;
    flex: 0 9rem;
  }

  .navigation__logo-box a:link, .navigation__logo-box a:visited {
    justify-content: start;
  }

  .navigation__logo-box svg {
    width: 5rem;
    height: 5rem;
  }

  .navigation__link-box {
    visibility: hidden;
    box-shadow: var(--shadow);
    height: 100vh;
    width: 50%;
    z-index: 51000;
    border-radius: 2.7rem 0 0 2.7rem;
    padding: .8rem 2rem;
    transition: all .3s;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .navigation__links {
    height: 100%;
    justify-content: space-around;
    align-items: stretch;
    list-style: none;
    display: flex;
  }

  .navigation__link-box--active {
    visibility: visible;
    transform: translateX(0);
  }

  .navigation__link-box .navigation__links {
    grid-template-rows: repeat(5, 8rem);
    place-content: center start;
    align-items: center;
    display: grid;
  }

  .navigation__link-box .navigation__links li:before {
    display: none;
  }

  .navigation__link-box .navigation__links li:last-of-type {
    left: 2.5rem;
  }

  .navigation__link-box .navigation__links li:last-of-type button:after {
    content: attr(title);
    color: var(--color-secondary);
    font-family: Dosis, sans-serif;
    font-size: 1.9rem;
    position: absolute;
    top: calc(50% - 1.3rem);
    right: -8rem;
  }

  .navigation__link-box .navigation__links svg {
    width: 4rem;
    height: 4rem;
    margin: 1rem;
  }

  .navigation__current:after {
    height: 180%;
    width: .7rem;
    border-radius: .9rem;
    top: -40%;
    left: -2.5rem;
  }

  .navigation__cover--active {
    width: 100%;
    height: 100vh;
    background-color: var(--color-black);
    z-index: 50000;
    opacity: .1;
    visibility: visible;
    transition: all .3s;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
  }

  .navigation__button {
    z-index: 51003;
    width: 3.5rem;
    height: 3.5rem;
    background-color: #0000;
    border: none;
    align-content: space-between;
    align-items: center;
    display: grid;
  }

  .navigation__button > * {
    width: 100%;
    height: .5rem;
    background-color: var(--color-black);
    border-radius: 5rem;
    transition: all .3s;
  }

  .navigation__button--active div:first-of-type {
    transform-origin: 0;
    transform: rotate(45deg)scaleX(1.215);
  }

  .navigation__button--active div:nth-of-type(2) {
    opacity: 0;
  }

  .navigation__button--active div:last-of-type {
    transform-origin: 0;
    transform: rotate(-45deg)scaleX(1.215);
  }

  .navigation__phone-menu {
    grid-column-gap: 1.3rem;
    grid-template-columns: 1fr min-content;
    place-items: center end;
    display: grid;
  }

  .navigation__page-name {
    text-transform: uppercase;
    letter-spacing: .1rem;
    color: var(--color-black);
    font-size: 1.9rem;
    font-weight: 600;
    display: block;
  }
}

@media only screen and (max-width: 50em) {
  .navigation__links a:link {
    font-size: 2.7rem;
  }

  .navigation__link-box {
    width: 80%;
  }
}

.heading-1 {
  color: var(--color-black-light);
  padding-left: .7rem;
  font-size: 5.2rem;
}

.heading-2 {
  text-transform: uppercase;
  letter-spacing: .1rem;
  color: var(--color-black-light);
  padding-bottom: 2.1rem;
  font-family: Dosis, sans-serif;
  font-size: 4.4rem;
  line-height: 100%;
}

@media only screen and (max-width: 34.375em) {
  .heading-2 {
    text-align: center;
  }
}

.heading-3 {
  text-transform: uppercase;
  letter-spacing: .15rem;
  color: var(--color-black-light);
  font-family: Dosis, sans-serif;
  font-size: 2.6rem;
  font-weight: 600;
}

.heading-4 {
  color: var(--color-black);
  letter-spacing: .1rem;
  font-family: Dosis, sans-serif;
  font-size: 2.1rem;
  font-weight: 600;
}

.heading-5 {
  color: var(--color-white-dark);
  text-transform: uppercase;
  letter-spacing: .2rem;
  font-family: Dosis, sans-serif;
  font-size: 2rem;
  font-weight: 700;
}

.heading-6 {
  color: var(--color-black);
  letter-spacing: .1rem;
  font-family: Dosis, sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}

.paragraph {
  max-width: 55%;
  font-family: Open Sans, sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
}

@media only screen and (max-width: 88em) {
  .paragraph {
    max-width: 72%;
  }
}

@media only screen and (max-width: 34.375em) {
  .paragraph {
    max-width: 100%;
  }
}

.paragraph--center {
  margin: auto;
}

@media only screen and (max-width: 34.375em) {
  .paragraph--center {
    text-align: left;
  }
}

.paragraph--wide {
  max-width: 80%;
}

@media only screen and (max-width: 34.375em) {
  .paragraph--wide {
    max-width: 100%;
  }
}

.paragraph--max {
  max-width: 100%;
}

.main {
  max-width: 190rem;
  margin: auto;
  padding: 0 5rem;
}

@media only screen and (max-width: 54.37em) {
  .main {
    padding: .5rem;
  }
}

@media only screen and (max-width: 24em) {
  .main {
    padding: 0;
  }
}

.footer {
  margin-top: var(--section-space);
  padding: 0 5rem;
}

.footer__container {
  max-width: 180rem;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 5rem 5rem 0 0;
  margin: auto;
  padding: 7rem 12rem;
  font-family: Open Sans, sans-serif;
  font-size: 1.6rem;
}

.footer__text {
  grid-column-gap: 8rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  display: grid;
}

.footer ul {
  padding-top: 1rem;
  line-height: 1.5;
  list-style: none;
}

.footer ul li {
  padding-top: .7rem;
}

.footer ul li:not(:last-child) {
  padding-bottom: .5rem;
}

.footer__nav-list a span:after {
  content: " →";
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
  display: inline;
  position: relative;
  left: 1rem;
}

.footer__nav-list a:link, .footer__nav-list a:visited {
  color: inherit;
  text-decoration: none;
}

.footer__nav-list a:hover span:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}

.footer__nav-list a:active span:after {
  left: .5rem;
}

.footer__source-code-text, .footer__about-text {
  padding-top: 1rem;
  line-height: 1.5;
}

.footer__source-code-text:not(:last-of-type), .footer__about-text:not(:last-of-type) {
  padding-top: 2rem;
}

.footer__contact {
  justify-content: end;
  display: flex;
}

.footer__contact a:link, .footer__contact a:visited {
  margin-left: 2rem;
  transition: all .2s;
}

.footer__contact a:hover {
  transform: scale(1.03);
}

.footer__contact a:active {
  transform: scale(.97);
}

.footer__contact svg {
  height: 5rem;
  width: 5rem;
  stroke-width: 0;
  fill: var(--color-secondary);
  stroke: var(--color-secondary);
  stroke-dasharray: 100;
  stroke-dashoffset: 100px;
}

.footer__svg--active {
  animation: toNormal 1s ease-out forwards;
}

@media only screen and (max-width: 88em) {
  .footer__text {
    grid-gap: 5rem;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  }

  .footer__contact {
    justify-content: center;
    padding-top: 5rem;
  }

  .footer__contact a:link, .footer__contact a:visited {
    margin: 0 1rem;
  }

  .footer svg {
    height: 7rem;
    width: 7rem;
  }
}

@media only screen and (max-width: 54.37em) {
  .footer {
    padding: 0 .5rem;
  }

  .footer__container {
    padding: 7rem 1rem;
  }
}

@media only screen and (max-width: 24em) {
  .footer {
    padding: 0;
  }
}

.header {
  margin: 24rem auto 14rem;
}

@media only screen and (max-width: 34.375em) {
  .header {
    margin: 15rem auto 10rem;
  }
}

.header__container {
  justify-content: center;
  align-items: center;
  padding: 12rem 0;
  display: flex;
}

.header__container svg {
  width: 7.1rem;
  height: 7.1rem;
  fill: var(--color-secondary);
  color: var(--color-secondary);
  margin-right: 1rem;
}

.header * {
  position: relative;
}

.header h1 {
  padding: 0;
}

.home-header {
  height: 70vh;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 20rem;
  display: grid;
}

.home-header__svgs {
  justify-content: end;
  align-items: flex-end;
  padding-right: 2.5rem;
  display: flex;
}

.home-header__svgs > * {
  filter: drop-shadow(var(--shadow) );
  opacity: 0;
  padding: 0 2.3rem;
  position: relative;
}

.home-header__svgs-webpage-1 {
  flex: 0 0 38%;
  display: block;
}

.home-header__svgs-webpage-2 {
  flex: 0 0 14%;
  display: block;
}

.home-header__svgs-webpage-3 {
  flex: 0 0 8%;
  display: block;
}

.home-header__svg-1--active {
  animation: webpageHome3 3.4s forwards;
}

.home-header__svg-2--active {
  animation: webpageHome2 3.1s forwards;
}

.home-header__svg-3--active {
  animation: webpageHome1 2.7s forwards;
}

.home-header__title {
  flex: 0 0 50%;
}

.home-header__heading {
  background-image: linear-gradient(var(--color-primary), var(--color-primary-light) );
  border: 2rem solid var(--color-primary-opacity-05);
  color: var(--color-black-dark);
  letter-spacing: .2rem;
  border-right: none;
  border-radius: 5rem 0 0 5rem;
  margin-left: 2.5rem;
  padding: 13rem 7rem;
  font-size: 7.5rem;
  font-weight: 400;
  transition: all .3s;
}

.home-header__heading * {
  position: relative;
}

.home-header__heading-first {
  font-weight: 600;
}

.home-header__heading-sub {
  width: auto;
  letter-spacing: .1rem;
  margin-top: 1.5rem;
  font-size: 4.4rem;
  display: inline-block;
}

.home-header__heading-sub span {
  font-weight: 700;
}

.home-header__hide {
  color: #5552;
}

.home-header__heading-sub--hidden {
  color: #fff0;
}

@media only screen and (max-width: 88em) {
  .home-header {
    height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    padding-bottom: 10rem;
  }

  .home-header__svgs {
    grid-area: 1 / 1 / 2 / 2;
    align-self: end;
    padding-right: 0;
    transform: translate(0, 50%);
  }

  .home-header__title {
    background-color: var(--color-primary);
    border-radius: 5rem;
    grid-area: 1 / 1 / 2 / 2;
    padding: 5rem 5rem 25rem;
  }

  .home-header__heading {
    background-image: none;
    background-color: var(--color-primary);
    box-shadow: var(--shadow-light);
    color: var(--color-black-dark);
    text-align: center;
    border: none;
    border-radius: 5rem;
    margin-left: 0;
    padding: 5rem 10rem;
  }
}

@media only screen and (max-width: 50em) {
  .home-header {
    height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-content: stretch;
    justify-items: stretch;
    padding: 0 2rem 10rem;
  }

  .home-header__title {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .home-header__svgs {
    grid-column-gap: 3rem;
    grid-template-columns: 13fr 5fr 3fr;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    padding: 0 3rem;
    display: grid;
  }

  .home-header__svgs > * {
    filter: drop-shadow(var(--shadow) );
    opacity: 0;
    width: 100%;
    padding: 0;
    position: relative;
  }

  .home-header__heading {
    letter-spacing: .05rem;
    padding-left: 0;
    padding-right: 0;
    font-size: 5.9rem;
  }

  .home-header__heading-sub {
    letter-spacing: .05rem;
    font-size: 3.8rem;
  }
}

@media only screen and (max-width: 34.375em) {
  .home-header {
    height: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    justify-content: stretch;
    justify-items: stretch;
    margin-top: 11rem;
    padding: 0 1rem 10rem;
  }

  .home-header__title {
    padding-bottom: 20rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .home-header__svgs {
    grid-column-gap: 3rem;
    grid-template-columns: 13fr 5fr 3fr;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    padding: 0 3rem;
    display: grid;
  }

  .home-header__svgs > * {
    filter: drop-shadow(var(--shadow) );
    opacity: 0;
    width: 100%;
    padding: 0;
    position: relative;
  }

  .home-header__heading {
    padding: 4rem 0;
    font-size: 5.5rem;
  }

  .home-header__heading-sub {
    margin-top: 0;
    font-size: 3.3rem;
    line-height: 1;
  }
}

.edu-tiles {
  margin-bottom: var(--section-space);
  grid-template-columns: [first-col] 1fr[second-col] 1fr[third-col];
  display: grid;
}

@media only screen and (max-width: 88em) {
  .edu-tiles {
    grid-template-rows: [first-row] min-content[second-row] 1fr[third-row];
    grid-template-columns: [first-col] 4fr[second-col] 1fr[third-col];
  }
}

@media only screen and (max-width: 34.375em) {
  .edu-tiles {
    grid-template-columns: 1fr;
  }
}

.edu-tiles__container {
  grid-column-gap: 5rem;
  grid-column: first-col / second-col;
  grid-template-columns: repeat(2, min-content);
  margin-top: 5rem;
  display: grid;
}

@media only screen and (max-width: 88em) {
  .edu-tiles__container {
    grid-column: 1 / -1;
  }
}

@media only screen and (max-width: 54.37em) {
  .edu-tiles__container {
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
  }
}

.edu-tiles__img {
  width: 70%;
  grid-column: second-col / third-col;
  place-self: end;
  display: block;
}

@media only screen and (max-width: 88em) {
  .edu-tiles__img {
    grid-area: first-row / second-col / second-row / third-col;
  }
}

@media only screen and (max-width: 34.375em) {
  .edu-tiles__img {
    width: 30%;
    grid-column: 1 / -1;
    justify-self: center;
    padding-bottom: 3rem;
  }
}

.edu-tiles__tile {
  box-shadow: var(--shadow);
  color: var(--color-black);
  border-radius: 2.7rem;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
}

.edu-tiles__tile-border {
  background-color: var(--color-secondary);
  width: 1rem;
  height: 100rem;
  position: absolute;
  top: 0;
  left: 0;
}

.edu-tiles__tile-heading {
  z-index: 20;
  background-color: var(--color-white-dark);
  border-radius: 0 .9rem .9rem 0;
  grid-template-columns: max-content;
  justify-content: center;
  margin: 3rem 4rem 2rem 0;
  padding: 1rem 3rem 1rem 7rem;
  display: grid;
  position: relative;
}

@media only screen and (max-width: 54.37em) {
  .edu-tiles__tile-heading {
    margin-right: 3rem;
  }
}

.edu-tiles__tile-college {
  align-items: center;
  padding-left: 2rem;
  display: flex;
}

.edu-tiles__tile-college svg {
  height: 3.3rem;
  width: 3.3rem;
  fill: #00f;
  padding-top: .3rem;
  padding-right: .5rem;
  display: block;
}

.edu-tiles__tile-name {
  letter-spacing: .05rem;
  font-size: 1.8rem;
  font-weight: 600;
}

.edu-tiles__tile-location {
  text-transform: uppercase;
  letter-spacing: .05rem;
  font-size: 1.4rem;
}

.edu-tiles__tile-duration {
  align-items: center;
  padding: 2rem 2rem 2rem 2.3rem;
  display: flex;
}

.edu-tiles__tile-duration svg {
  height: 2.8rem;
  width: 2.8rem;
  padding-right: .6rem;
  display: block;
}

.edu-tiles__tile-date {
  font-size: 1.7rem;
}

.edu-tiles__tile-list {
  margin: 2rem 3rem 2.5rem 4.3rem;
  font-size: 1.6rem;
  line-height: 1.5;
  list-style-image: url("list-svg.56c97576.svg");
}

.edu-tiles__tile-list li {
  padding-bottom: 1rem;
}

.learning {
  padding-top: var(--section-space);
}

.learning__container {
  grid-column-gap: 20rem;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  justify-content: space-between;
  display: grid;
}

@media only screen and (max-width: 88em) {
  .learning__container {
    grid-column-gap: 7rem;
  }
}

@media only screen and (max-width: 54.37em) {
  .learning__container {
    grid-row-gap: 5rem;
    grid-column-gap: 0;
    grid-template-columns: auto;
  }
}

@media only screen and (max-width: 34.375em) {
  .learning__container {
    grid-row-gap: 0;
  }
}

.learning__container > * {
  grid-column: 1 / -1;
}

.learning__img {
  width: 100%;
  grid-column: span 1;
  align-self: start;
  display: block;
}

.learning__img:not(:first-child) {
  justify-self: end;
}

@media only screen and (max-width: 54.37em) {
  .learning__img {
    width: 30%;
    grid-area: 1 / span 2 / 2;
    justify-self: center;
  }

  .learning__img:not(:first-child) {
    justify-self: center;
  }
}

@media only screen and (max-width: 54.37em) {
  .learning__img {
    margin-bottom: 3rem;
  }
}

.learning__heading {
  grid-column: span 1;
}

.learning__heading--centered {
  text-align: center;
  grid-column: 1 / -1;
}

.learning__buttons {
  grid-template-columns: repeat(2, max-content);
  padding-top: 2rem;
  display: grid;
}

@media only screen and (max-width: 34.375em) {
  .learning__buttons {
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.learning__buttons > * {
  margin: 0 1rem 0 0;
}

@media only screen and (max-width: 34.375em) {
  .learning__buttons > * {
    justify-content: center;
    margin: 0;
  }
}

.learning__spacing {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 29em) {
  .learning__spacing {
    margin-bottom: 6.3rem;
  }
}

.learning__heading--centered .learning__buttons {
  text-align: center;
  justify-content: center;
}

.show-internet-mail {
  height: 100%;
  width: 100%;
  background-color: var(--color-pure-white);
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  grid-template-rows: 2fr 3fr 10rem;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.4);
}

.show-internet-mail__button {
  width: 5.8rem;
  height: 5.8rem;
  justify-content: center;
  justify-self: center;
  align-items: center;
  display: flex;
}

.show-internet-mail__button svg {
  width: 4.5rem;
  height: 4.5rem;
  margin-bottom: 0;
  display: block;
  position: relative;
}

.show-internet-mail p {
  letter-spacing: .1rem;
  font-family: Dosis, sans-serif;
  font-size: 2.2rem;
}

.show-internet-mail p svg {
  fill: var(--color-black);
  height: 2rem;
  width: 2rem;
  margin: 0 .2rem;
  display: inline-block;
  position: relative;
  top: .15rem;
}

.show-internet-mail img {
  height: 2rem;
  width: 2rem;
  display: inline-block;
}

.show-internet-mail--active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.hi-there {
  text-align: center;
  padding-bottom: var(--section-space);
  justify-content: center;
  display: grid;
  margin-top: 0 !important;
}

.hi-there__text {
  text-transform: uppercase;
  background-image: linear-gradient(to bottom right, #fff600, #d8cb00);
  border-radius: 2.7rem;
  margin: 5rem auto;
  padding: 3rem 6.5rem;
  font-size: 5.7rem;
  font-weight: 600;
  transform: skewX(-10deg);
}

.hi-there__text p {
  color: var(--color-black);
  transform: skewX(10deg);
}

.body--dark-mode .hi-there__text {
  opacity: .9;
}

.body--dark-mode .hi-there__text p {
  color: var(--color-pure-white);
}

.hi-there__img {
  width: 100%;
}

@media only screen and (max-width: 34.375em) {
  .hi-there__img {
    width: 75%;
    justify-self: center;
  }
}

.hi-there img.hi-there__img {
  display: none;
}

@media (prefers-reduced-motion) {
  .hi-there object.hi-there__img {
    display: none;
  }

  .hi-there img.hi-there__img {
    display: block;
  }
}

.four-o-four {
  grid-row-gap: 2rem;
  background-image: linear-gradient(to right bottom, var(--color-pure-white), var(--color-gray-light) );
  height: 100vh;
  align-content: start;
  align-items: center;
  padding: 15rem 0;
  display: grid;
}

.four-o-four__svg {
  max-width: 50vh;
  filter: drop-shadow(var(--shadow) );
  margin: 3rem auto 0;
  display: inline-block;
}

@media only screen and (max-width: 34.375em) {
  .four-o-four__svg {
    width: 80%;
  }
}

/*# sourceMappingURL=index.01f8a1fa.css.map */
