@keyframes page {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes webpageHome1 {
  0% {
    transform: translateX(-20rem);
    opacity: 0; }
  15% {
    transform: translateX(-20rem);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes webpageHome2 {
  0% {
    transform: translateX(-20rem);
    opacity: 0; }
  25% {
    transform: translateX(-20rem);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes webpageHome3 {
  0% {
    transform: translateX(-20rem);
    opacity: 0; }
  35% {
    transform: translateX(-20rem);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes circle-svg {
  0% {
    stroke-dashoffset: 85; }
  100% {
    stroke-dashoffset: -85; } }

@keyframes rotate-svg {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes toNormal {
  0% {
    stroke-dashoffset: 100; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes cursor {
  0% {
    background-color: transparent; }
  50% {
    background-color: transparent; }
  51% {
    background-color: var(--color-black-light); } }

.background {
  background-color: var(--color-white);
  border-radius: 5rem;
  padding: var(--section-space) 0;
  margin-top: var(--section-space); }
  .background--dark {
    background-color: var(--color-white-dark); }

.max-content-width {
  max-width: 140rem;
  padding-left: 5rem;
  padding-right: 5rem;
  margin: auto; }
  @media only screen and (max-width: 34.375em) {
    .max-content-width {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media only screen and (max-width: 24em) {
    .max-content-width {
      padding-left: 1rem;
      padding-right: 1rem; } }

body .center {
  text-align: center; }

.a-button {
  width: 100%;
  text-decoration: none;
  display: flex;
  outline: none;
  flex-direction: column; }

.break::after {
  content: "";
  display: block; }

body .cursor::after {
  content: "";
  display: block;
  height: 100%;
  width: .4rem;
  position: absolute;
  right: -.3rem;
  top: 0;
  z-index: 10;
  background-color: var(--color-black-light);
  border-radius: .2rem; }

.cursor--flash::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: .4rem;
  position: absolute;
  right: -.3rem;
  top: 0;
  z-index: 10;
  background-color: var(--color-black-light);
  border-radius: .2rem;
  animation: cursor .9s infinite linear; }

.hide {
  opacity: 0.2; }

.display-none {
  display: none; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

:root {
  --color-primary-dark: #D8CB00;
  --color-primary:#FFF600;
  --color-primary-light:#FFF724;
  --color-secondary:#0A4BFF;
  --color-secondary-dark: #0B0EDF;
  --color-black-dark: #2A2A2A;
  --color-black: #3b3b3b;
  --color-black-light:#484848;
  --color-gray-dark:#707070;
  --color-gray:  #D3D3D3;
  --color-gray-light:  #E1E1E1;
  --color-white-dark: #f0f0f0;
  --color-white: #F9F9F9;
  --color-pure-white: #fff;
  --color-primary-opacity-05: #fdff90;
  --color-secondary-card-dark: #2C6ECB;
  --color-secondary-card-light: #69A3FF;
  --color-white-opacity-05:#ffffff9a;
  --color-white-opacity-03:#ffffff55;
  --color-gray-opacity-02: #D3D3D323;
  --section-space: 14rem;
  --shadow-big: 0 1rem 4rem rgba(42, 42, 42, .3);
  --shadow:  0 0 2rem rgba(42, 42, 42, .2);
  --shadow-small:  0 0 .5rem rgba(42, 42, 42, .4);
  --shadow-light:  0 0 2rem rgba(42, 42, 42, .1);
  --color-green: #18cc24; }

html {
  box-sizing: border-box;
  font-size: 62.5%; }
  @media only screen and (max-width: 78.125em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 24em) {
    html {
      font-size: 48%; } }

body {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Dosis", sans-serif;
  color: var(--color-black-dark);
  animation: page 1s;
  background-color: var(--color-pure-white); }
  @media only screen and (max-width: 34.375em) {
    body {
      --section-space: 10rem; } }

.body--dark-mode {
  background-color: #212121;
  --color-primary-dark: #D8CB00;
  --color-primary:#262cd8;
  --color-primary-light:#2c30a0;
  --color-secondary:#d1a426;
  --color-secondary-dark: #0B0EDF;
  --color-black-dark: #cccccc;
  --color-black: #b4b4b4;
  --color-black-light:#a6a6a6;
  --color-gray-dark:#707070;
  --color-gray: #454545;
  --color-gray-light:  #3f3f3f;
  --color-white-dark: #303030;
  --color-white: #2a2a2a;
  --color-pure-white: #272727;
  --color-primary-opacity-05: #272673;
  --color-secondary-card-dark: #b78948;
  --color-secondary-card-light: #926828;
  --shadow-big: 0 1rem 4rem rgba(0, 0, 0, .5);
  --shadow:  0 0 2rem rgba(0, 0, 0, .5);
  --shadow-small:  0 0 .5rem rgba(0, 0, 0, .4);
  --shadow-light:  0 0 2rem rgba(0, 0, 0, .3); }

*::selection {
  background-color: var(--color-secondary);
  color: var(--color-white); }

.plus {
  height: 3.5rem;
  width: 3.5rem;
  display: grid;
  background-color: var(--color-white-opacity-03);
  border-radius: 10rem;
  align-items: center;
  padding: .5rem; }
  .plus__horizontal {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: 100%;
    height: .4rem;
    border-radius: 2rem;
    transition: all .2s;
    background-color: var(--color-white); }
    @media (prefers-reduced-motion) {
      .plus__horizontal {
        transition: none; } }
  .plus__vertical {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    transform: rotate(90deg);
    border-radius: 2rem;
    width: 100%;
    height: .4rem;
    transition: all .2s;
    background-color: var(--color-gray); }
    @media (prefers-reduced-motion) {
      .plus__vertical {
        transition: none; } }

.button {
  overflow: hidden;
  outline: none;
  cursor: pointer;
  border: none;
  text-align: center;
  position: relative;
  padding: 2rem;
  background-color: var(--color-secondary);
  border-radius: 5rem;
  margin-top: auto;
  margin-left: 5rem;
  margin-right: 5rem;
  letter-spacing: .1rem;
  font-family: "Dosis", sans-serif;
  box-shadow: var(--shadow);
  font-weight: 500;
  font-size: 2.2rem;
  color: var(--color-gray-light);
  transition: all .2s;
  align-self: stretch;
  z-index: 20; }
  .button > * {
    position: relative;
    z-index: 2000; }
  @media (prefers-reduced-motion) {
    .button {
      transform: scale(1) !important;
      transition: none; } }

.button:hover {
  color: var(--color-white);
  transform: scale(1.01); }

.button:active, .button:focus {
  transform: scale(0.98);
  outline: none; }

.button::before {
  content: "";
  display: block;
  background-image: radial-gradient(#ffffff32, #ffffff11);
  height: 7rem;
  width: 7rem;
  border-radius: 10rem;
  position: absolute;
  top: 0%;
  right: calc(50% - 3.5rem);
  opacity: 0;
  transform: scale(1);
  transition: all .2s; }

.button::after {
  content: "";
  display: block;
  background-image: radial-gradient(#ffffff22, transparent);
  position: absolute;
  right: -110%;
  top: -150%;
  border-radius: 50%;
  transition: all .4s;
  height: 370%;
  width: 110%; }

.button:hover::after {
  transform: translate(-100%, 1rem); }
  @media (prefers-reduced-motion) {
    .button:hover::after {
      transform: none; } }

.button:active svg {
  transform: rotate(90deg) translateX(0.5rem); }

.button:active::before {
  transform: scale(5);
  opacity: 1; }
  @media (prefers-reduced-motion) {
    .button:active::before {
      opacity: 0;
      transform: none; } }

.button--inline:link, .button--inline:visited {
  color: var(--color-secondary); }

.button--inline:hover, .button--inline:active {
  color: var(--color-secondary); }

.button--gray-large {
  font-size: 2.4rem;
  color: var(--color-black);
  background-color: var(--color-gray-light);
  padding: 2.7rem; }
  .button--gray-large:hover {
    color: var(--color-black-dark); }
  .button--gray-large::after {
    background-image: radial-gradient(#ffffff55, #ffffff11); }
  .body--dark-mode .button--gray-large::after {
    background-image: radial-gradient(#ffffff13, #ffffff01); }
  .body--dark-mode .button--gray-large::before {
    background-image: radial-gradient(#ffffff13, #ffffff01); }

.button--down-arrow {
  display: flex;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem; }
  .button--down-arrow > *:not(svg) {
    padding: 0 1rem; }
  .button--down-arrow svg {
    transition: all .2s;
    position: relative;
    z-index: 200;
    width: 3rem;
    height: 2.3rem;
    transform: rotate(90deg); }

.button--underline {
  overflow: visible;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0;
  border-radius: 0;
  color: var(--color-black);
  text-decoration: none;
  font-size: 1.8rem; }
  .button--underline span {
    display: block;
    height: .5rem;
    width: 100%;
    margin-top: .3rem;
    background-color: var(--color-gray);
    transition: all .2s;
    border-radius: 2.7rem; }
  .button--underline::before, .button--underline::after {
    display: none; }
  .button--underline:hover {
    color: var(--color-black-dark); }
    .button--underline:hover span {
      transform: translateY(0.1rem); }

.button--narrow {
  padding: 1.3rem 2rem;
  font-size: 1.7rem; }

.button--plus {
  display: flex;
  padding: .8rem 2rem;
  align-items: center;
  font-size: 1.8rem; }
  .button--plus span {
    transform: translateX(0.9rem); }

.button--no-margin {
  margin: 0; }

.button--outline {
  background-color: transparent;
  font-weight: 500;
  color: var(--color-black-light);
  border: solid 0.3rem var(--color-gray);
  box-shadow: none;
  padding: 1rem 2rem; }
  .button--outline::after, .button--outline::before {
    display: none; }
  .button--outline:hover {
    color: var(--color-black);
    filter: drop-shadow(var(--shadow)); }

.button--x {
  display: block;
  padding: 0;
  width: 4.5rem;
  height: 4.5rem; }

.button--x:active svg {
  transform: none; }

.button--hidden {
  cursor: not-allowed;
  opacity: .3;
  visibility: hidden; }
  .button--hidden:hover {
    transform: none; }
  .button--hidden:active {
    transform: none; }

.info {
  padding: 7rem;
  margin-top: 8rem;
  background-color: var(--color-gray-opacity-02);
  transition: all .2s;
  border-radius: 2.7rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  grid-gap: 5rem; }
  @media only screen and (max-width: 34.375em) {
    .info {
      border-radius: 0.9rem;
      margin-top: 5rem;
      padding: 3rem;
      background-color: transparent; } }
  @media only screen and (max-width: 24em) {
    .info {
      padding: 0rem 2rem; } }
  .info__box {
    display: grid;
    grid-template-columns: 4.5rem [col-2] 1fr [col-3];
    grid-template-rows: [row-1] 4.5rem [row-2] 1fr [row-3];
    align-items: center;
    grid-column-gap: 1.5rem; }
  .info__svg {
    padding: .3rem;
    display: block;
    width: 100%;
    height: 100%;
    fill: var(--color-secondary); }
  .info__heading {
    grid-column: col-2 / col-3;
    grid-row: row-1 / row-2;
    justify-self: start; }
    @media only screen and (max-width: 34.375em) {
      .info__heading {
        justify-self: unset; } }
  .info__text {
    grid-column: col-2 / col-3;
    align-self: start; }
  @media only screen and (max-width: 34.375em) {
    .info--center {
      text-align: center; } }
  @media only screen and (max-width: 34.375em) {
    .info--center .info__box {
      grid-template-columns: [col-2] 1fr [col-3];
      grid-template-rows: [row-0] 5rem [row-1] 5rem [row-2] 1fr; } }

.to-top {
  border: none;
  background-color: transparent;
  position: fixed;
  right: 1%;
  bottom: 1.7%;
  z-index: 10000;
  transition: all 0.2s 0.3s cubic-bezier(0.41, 1.47, 0.9, 1.02);
  opacity: 0;
  transform: scale(0); }
  @media only screen and (max-width: 54.37em) {
    .to-top {
      bottom: 1.5rem;
      right: 1.5rem; } }
  .to-top__arrow {
    border-radius: 5rem;
    box-shadow: var(--shadow-light);
    background-image: linear-gradient(to right bottom, var(--color-secondary-card-dark), var(--color-secondary));
    width: 7rem;
    height: 7rem;
    transition: all .3s; }
    @media only screen and (max-width: 54.37em) {
      .to-top__arrow {
        width: 5.7rem;
        height: 5.7rem; } }
    .to-top__arrow svg {
      transition: all .2s;
      transform: rotate(-90deg);
      padding: 1.5rem;
      width: 100%;
      height: 100%;
      filter: brightness(0%) invert(100%); }
  .to-top__arrow:hover {
    transform: scale(1.03);
    background-color: var(--color-gray); }
    @media (prefers-reduced-motion) {
      .to-top__arrow:hover {
        transform: none; } }
  .to-top:active .to-top__arrow svg {
    transform: rotate(-90deg) translateX(0.3rem); }
    @media (prefers-reduced-motion) {
      .to-top:active .to-top__arrow svg {
        transform: rotate(-90deg); } }

.to-top--active {
  opacity: 1;
  transform: scale(1); }

.cards {
  padding-bottom: var(--section-space); }
  .cards__container {
    display: grid;
    margin-top: 7rem;
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    grid-column-gap: 7rem;
    font-family: "Open Sans", sans-serif; }
  .cards__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: var(--color-pure-white);
    box-shadow: var(--shadow);
    border-radius: 2.7rem;
    padding: 3.7rem 4.1rem;
    overflow: hidden; }
    .body--dark-mode .cards__card-night-mode-hide {
      display: none; }
  .cards__card-paragraph {
    text-align: center;
    padding: 1.8rem 0 4rem 0;
    line-height: 1.5;
    font-size: 1.7rem;
    margin-bottom: auto; }
  .cards__card-night-mode {
    display: none; }
    .body--dark-mode .cards__card-night-mode {
      display: block; }
  .cards__card > svg {
    width: 9rem;
    height: 9rem;
    margin-bottom: 1.8rem;
    stroke: #444343;
    stroke-width: 0;
    fill: #444343;
    stroke-dasharray: 100;
    stroke-dashoffset: 100; }
    .body--dark-mode .cards__card > svg {
      filter: invert(80%); }
  .cards .cards__svg--active {
    animation: toNormal 2s;
    animation-fill-mode: forwards; }

@media only screen and (max-width: 88em) {
  .cards {
    padding-bottom: var(--section-space); }
    .cards__container {
      grid-gap: 5rem; }
    .cards svg {
      width: 7rem;
      height: 7rem; } }

@media only screen and (max-width: 34.375em) {
  .cards__container {
    margin-top: 5rem; } }

.wide-card {
  margin: var(--section-space) auto 0; }
  .wide-card__card {
    background-image: linear-gradient(to bottom right, var(--color-secondary-card-dark), var(--color-secondary-card-light));
    padding: 11rem;
    margin-top: 7rem;
    border-radius: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center; }
    .wide-card__card a {
      display: flex;
      justify-self: end;
      text-decoration: none;
      justify-content: end; }
      .wide-card__card a > * {
        margin: 0;
        padding-left: 7rem;
        padding-right: 7rem;
        align-self: center; }
  .wide-card .wide-card__text {
    color: var(--color-pure-white); }

@media only screen and (max-width: 88em) {
  .wide-card__card {
    padding: 10rem 8rem; }
    .wide-card__card a {
      padding-left: 2rem; } }

@media only screen and (max-width: 50em) {
  .wide-card__card {
    grid-gap: 3.5rem;
    grid-template-columns: 1fr; }
    .wide-card__card a {
      padding-top: 1rem;
      padding-left: 0;
      justify-self: center; } }

@media only screen and (max-width: 34.375em) {
  .wide-card__card {
    margin-top: 5rem;
    padding: 7rem 3rem; } }

.tile-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem; }
  @media only screen and (max-width: 34.375em) {
    .tile-title {
      padding-top: 3rem; } }
  .tile-title__text {
    background-color: var(--color-white-dark);
    border-radius: 0.9rem 0.9rem 0 0;
    padding: 1.5rem 2.5rem; }
  .tile-title__line {
    width: 100%;
    height: 1rem;
    position: relative;
    top: -1rem;
    background-color: var(--color-white-dark);
    border-radius: 5rem;
    margin-bottom: 2rem; }

.tiles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  grid-gap: 5rem;
  width: 100%; }
  .tiles__tile {
    display: grid;
    grid-template-rows: 20rem min-content max-content 1fr max-content;
    overflow: hidden;
    border-radius: 0.9rem;
    box-shadow: var(--shadow); }
  .tiles__img {
    display: grid;
    filter: brightness(90%);
    overflow: hidden; }
    .tiles__img img {
      width: 100%;
      filter: blur(0.15rem) brightness(80%); }
  .tiles__heading {
    background-color: var(--color-primary-light);
    filter: brightness(95%);
    border-radius: 2rem;
    box-shadow: var(--shadow);
    padding: 1.6rem 3.5rem;
    justify-self: center;
    transform: translateY(-50%); }
  .tiles__list {
    list-style-image: url("/img/svg/list-svg.svg");
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;
    line-height: 1.5;
    margin: 0 4rem; }
    .tiles__list li:not(:last-child) {
      padding-bottom: 1rem; }
  .tiles__tags {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 3rem;
    align-self: end;
    margin: 1.1rem 0 0 2.58rem; }
  .tiles__tag {
    display: flex;
    align-items: center; }
    .tiles__tag-svg {
      display: block;
      width: 2.5rem;
      height: 2.5rem;
      fill: var(--color-green); }
    .tiles__tag-name {
      font-family: "Open Sans", sans-serif;
      font-size: 1.4rem;
      color: var(--color-black-light);
      font-weight: 700;
      padding-left: .4rem;
      text-transform: uppercase; }
  .tiles__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    align-self: end;
    align-items: end;
    padding: 2rem;
    margin-top: 2rem; }

.wide-tiles {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
  align-items: start;
  grid-gap: 4rem; }
  @media only screen and (max-width: 34.375em) {
    .wide-tiles {
      grid-template-columns: repeat(auto-fit, minmax(29rem, 1fr)); } }
  .wide-tiles__container {
    display: flex;
    flex-direction: column; }
  .wide-tiles__tile {
    background-color: var(--color-pure-white);
    border-radius: 0.9rem;
    box-shadow: var(--shadow);
    padding: 2rem; }
    .wide-tiles__tile:not(:first-child) {
      margin-top: 4rem; }
  .wide-tiles__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%; }
  .wide-tiles__img {
    display: block;
    flex: 0 0 4.2rem;
    margin-right: 1rem;
    width: 4.2rem;
    height: auto; }
    .body--dark-mode .wide-tiles__img {
      filter: brightness(150%) saturate(200%); }
  .wide-tiles__info {
    padding: 2rem 1rem;
    display: grid;
    grid-row-gap: 1rem; }
  .wide-tiles__date, .wide-tiles__duration {
    display: flex;
    align-items: center; }
    .wide-tiles__date p, .wide-tiles__duration p {
      font-family: "Open Sans", sans-serif;
      color: var(--color-black);
      padding-left: 1.8rem;
      font-size: 1.6rem;
      font-weight: 600; }
    .wide-tiles__date svg, .wide-tiles__duration svg {
      width: 2.2rem;
      height: 2.2rem;
      fill: var(--color-secondary);
      stroke: var(--color-secondary); }
  .wide-tiles__duration svg {
    width: 2.1rem;
    height: 2.1rem; }
  .wide-tiles__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .wide-tiles__buttons .button {
      align-self: flex-end; }
    @media only screen and (max-width: 34.375em) {
      .wide-tiles__buttons {
        flex-direction: column; }
        .wide-tiles__buttons .button {
          align-self: start; }
        .wide-tiles__buttons > *:last-child {
          margin-top: 2rem;
          align-self: stretch;
          align-items: center;
          justify-content: space-between; }
        .wide-tiles__buttons .button--hidden {
          display: none; } }
  .wide-tiles__open .plus__horizontal {
    transform: rotate(180deg); }
  .wide-tiles__open .plus__vertical {
    transform: rotate(360deg); }
  .wide-tiles__sub {
    margin-top: 2rem; }
    .wide-tiles__sub-tile {
      border-radius: 0.9rem;
      display: grid;
      grid-template-columns: [col-1] 1rem [col-2] 3fr [col-3] 4fr [col-4];
      grid-template-rows: 20rem;
      grid-auto-rows: 0;
      overflow: hidden;
      background-color: var(--color-white); }
      .wide-tiles__sub-tile:not(:last-child) {
        margin-bottom: 2rem; }
    .wide-tiles__sub-line {
      height: 20rem;
      width: 1rem;
      background-color: var(--color-gray); }
    .wide-tiles__sub-img {
      grid-column: col-2 / col-3;
      grid-row: 1 / 2;
      margin: 1.3rem;
      display: block;
      overflow: hidden;
      border-radius: 0.9rem; }
      .wide-tiles__sub-img img {
        width: 100%;
        filter: blur(0.3rem) brightness(70%); }
        .body--dark-mode .wide-tiles__sub-img img {
          filter: blur(0.4rem) brightness(110%); }
    .wide-tiles__sub-heading {
      position: relative;
      grid-column: col-2 / col-3;
      grid-row: 1 / 2;
      display: grid;
      align-items: center;
      justify-content: center;
      z-index: 2; }
      .wide-tiles__sub-heading > * {
        z-index: 20;
        color: var(--color-pure-white);
        text-shadow: 0 0 1.5rem var(--color-black); }
        .body--dark-mode .wide-tiles__sub-heading > * {
          color: #111;
          text-shadow: 0 0 1.5rem var(--color-black-dark); }
    .wide-tiles__sub-description {
      padding: 1.3rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .wide-tiles__sub-description h6 {
        padding-bottom: 1rem; }

.map {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-pure-white);
  z-index: 50000;
  padding: 2rem;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.4);
  transition: all .3s; }
  @supports (backdrop-filter: blur(2rem)) {
    .map {
      background-color: transparent;
      backdrop-filter: blur(0.5rem); } }
  @media only screen and (max-width: 29em) {
    .map {
      padding: .5rem; } }
  .map__button {
    position: fixed;
    top: 4.7rem;
    right: 3.1rem;
    display: grid;
    justify-content: center;
    align-items: center;
    width: 5.8rem;
    height: 5.8rem;
    margin: 0 2rem; }
    @media only screen and (max-width: 29em) {
      .map__button {
        top: 3.4rem;
        right: 2.2rem; } }
    .map__button svg {
      display: block;
      width: 4.5rem;
      height: 4.5rem; }
  .map iframe {
    background-color: var(--color-pure-white);
    border-radius: 0.9rem;
    width: 100%;
    height: 100%;
    border: 1.2rem solid var(--color-black); }
    @media only screen and (max-width: 29em) {
      .map iframe {
        border-width: .8rem; } }
  .map--active {
    visibility: visible;
    transform: scale(1);
    opacity: 1; }

.previewer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--color-pure-white);
  width: 100%;
  height: 100vh;
  z-index: 50000;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.4);
  transition: all .3s;
  display: grid;
  grid-template-rows: 5.7rem 1fr;
  grid-template-columns: 1fr;
  justify-items: center; }
  .previewer__heading {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 5.7rem;
    align-items: center;
    box-shadow: var(--shadow);
    justify-content: center;
    background-color: var(--color-pure-white); }
    @media only screen and (max-width: 54.37em) {
      .previewer__heading {
        grid-template-columns: 1fr 1fr; } }
  .previewer__title {
    padding-left: 2rem;
    font-weight: 600;
    font-size: 2rem; }
  .previewer__zooming {
    justify-self: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    @media only screen and (max-width: 54.37em) {
      .previewer__zooming {
        display: none; } }
  .previewer__zooming-in, .previewer__zooming-out {
    background-color: transparent;
    border: none;
    color: var(--color-secondary);
    margin: 0 1.3rem;
    padding: .5rem;
    border-radius: 5rem;
    display: inline-block; }
    .previewer__zooming-in svg, .previewer__zooming-out svg {
      margin: .5rem;
      display: block;
      width: 2.3rem;
      height: 2.3rem; }
  .previewer__zooming-in:hover, .previewer__zooming-out:hover {
    background-color: var(--color-white-dark); }
  .previewer__zooming-in:active svg, .previewer__zooming-out:active svg {
    transform: scale(0.95); }
  .previewer__zoom-level {
    text-align: center;
    font-family: inherit;
    border: .3rem solid transparent;
    border-radius: .7rem;
    outline: none;
    height: 3rem;
    width: 8rem;
    padding: .7rem .7rem .7rem .14rem;
    font-weight: 500;
    font-size: 1.7rem;
    position: relative;
    z-index: 500;
    background-color: rgba(225, 225, 225, 0.3);
    letter-spacing: .1rem; }
  .previewer__zoom-level:focus {
    outline: 0.3rem solid var(--color-gray);
    outline-offset: .3rem; }
  .previewer__zoom-level-percent {
    z-index: 200;
    position: absolute;
    left: 52.3%;
    font-weight: 600;
    font-size: 1.6rem; }
  .previewer__close {
    justify-self: end;
    align-self: center;
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 2rem; }
    .previewer__close svg {
      display: block;
      width: 3.5rem;
      height: 3.5rem; }
  .previewer__holder {
    padding-bottom: 5rem;
    display: grid;
    justify-content: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    overflow-y: scroll;
    z-index: -1; }
  .previewer__zoomer {
    transition: all .2s; }
  .previewer__img {
    margin-top: 3rem;
    width: 100%;
    display: block;
    box-shadow: var(--shadow-big); }
  .previewer--active {
    opacity: 1;
    visibility: visible;
    transform: scale(1); }

.tool-tip {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 500000;
  font-family: "Dosis", sans-serif;
  font-size: 1.7rem;
  letter-spacing: .1rem;
  color: var(--color-white);
  background-color: var(--color-black);
  padding: 1.1rem 1.9rem;
  border-radius: .3rem;
  box-shadow: var(--shadow-small);
  transition: all .1s;
  opacity: 0;
  transform: translateY(0.2rem);
  visibility: hidden; }
  .tool-tip::before {
    display: block;
    content: "";
    position: absolute;
    top: -1.9rem;
    left: calc(50% - 1rem);
    width: 1rem;
    height: 1rem;
    border: 1rem solid transparent;
    border-bottom: 1rem solid var(--color-black);
    z-index: 20000;
    clip-path: circle(10px at 50% 103%); }
  .tool-tip--side-left::before {
    border: 1rem solid transparent;
    border-left: 1rem solid var(--color-black);
    top: calc(50% - 1rem);
    left: 100%;
    clip-path: circle(10px at -3% 50%); }
  .tool-tip--side-right::before {
    z-index: 20000;
    border: 1rem solid transparent;
    border-right: 1rem solid var(--color-black);
    top: calc(50% - 1rem);
    left: -1.9rem;
    clip-path: circle(10px at 97% 50%); }
  .tool-tip--bottom::before {
    border: 1rem solid transparent;
    border-top: 1rem solid var(--color-black);
    top: calc(100% - .1rem);
    left: calc(50% - 1rem);
    clip-path: circle(10px at 50% -3%); }

.tool-tip--active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0); }

.navigation__logo-box, .navigation__link-box {
  background-color: var(--color-pure-white);
  border-radius: 2.7rem; }

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 2rem;
  margin: 2rem 0;
  overflow: hidden;
  filter: drop-shadow(var(--shadow));
  transition: all .3s;
  z-index: 20000; }
  .navigation__logo-box {
    flex: 0 1 10rem; }
    .navigation__logo-box a:link, .navigation__logo-box a:visited {
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%; }
    .navigation__logo-box svg {
      display: block;
      width: 50%;
      height: 50%;
      filter: drop-shadow(var(--shadow-small)) drop-shadow(var(--shadow));
      transition: all .2s; }
    .navigation__logo-box:hover svg {
      transform: scale(1.03); }
    .navigation__logo-box a:active, .navigation__logo-box a:focus svg {
      transform: scale(0.97); }
  .navigation__link-box {
    padding: .8rem 2rem;
    position: relative; }
  .navigation__links {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    height: 100%; }
    .navigation__links a:link, .navigation__links a:visited {
      height: 100%;
      outline: none;
      display: flex;
      text-decoration: none;
      align-items: center;
      line-height: 1;
      padding: 0 3.5rem;
      font-size: 2.3rem;
      color: var(--color-secondary);
      position: relative;
      z-index: 4;
      transition: all .2s; }
    .navigation__links a:focus, .navigation__links a:active {
      outline: none;
      transform: scale(0.97); }
    .navigation__links li {
      position: relative; }
    .navigation__links li:not(:last-of-type)::before {
      display: block;
      content: "";
      background-color: var(--color-white);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      border-radius: 2.7rem;
      opacity: .2;
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.41, 1.47, 0.9, 1.02); }
      @media (prefers-reduced-motion) {
        .navigation__links li:not(:last-of-type)::before {
          transition: all 0s; } }
    .navigation__links li:hover::before {
      transform: scale(1);
      opacity: 1; }
    .navigation__links button {
      cursor: pointer;
      border: none;
      background: none;
      height: 100%;
      outline: none;
      position: relative;
      z-index: 2; }
      .navigation__links button:hover svg {
        animation: circle-svg 4s linear infinite; }
        @media (prefers-reduced-motion) {
          .navigation__links button:hover svg {
            animation: none; } }
      .body--dark-mode .navigation__links button:hover svg {
        animation: rotate-svg 32s linear infinite; }
        @media (prefers-reduced-motion) {
          .body--dark-mode .navigation__links button:hover svg {
            animation: none; } }
    .navigation__links svg {
      display: block;
      width: 4.3rem;
      height: 4.3rem;
      margin: 0 3rem;
      transform: scale(0.97);
      transition: all .2s;
      stroke-width: 3;
      fill: var(--color-black-light);
      stroke: rgba(2, 112, 255, 0.356);
      stroke-dasharray: 85;
      stroke-dashoffset: 85; }
    .body--dark-mode .navigation__links svg {
      stroke: rgba(255, 217, 2, 0.192);
      fill: var(--color-secondary); }
    .navigation__links button:hover svg {
      filter: brightness(90%) drop-shadow(var(--shadow));
      transform: scale(1); }
    .navigation__links button:active svg {
      filter: brightness(90%) drop-shadow(var(--shadow));
      transform: scale(0.95); }
  .navigation__current::after {
    display: block;
    position: absolute;
    z-index: 3;
    bottom: .8rem;
    left: 0;
    border-radius: 0.9rem;
    content: "";
    height: .7rem;
    width: 70%;
    margin: 0 15%;
    transition: all .2s;
    background-color: var(--color-secondary); }
  .navigation--active {
    background-color: var(--color-pure-white);
    margin: 0;
    padding: 0;
    height: 7rem; }
    .navigation--active .navigation__logo-box svg {
      width: 59%;
      height: 59%; }
    .navigation--active .navigation__link-box {
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0; }
      .navigation--active .navigation__link-box svg {
        width: 3rem;
        margin: 0 2rem; }
    .navigation--active .navigation__current::after {
      border-radius: .5rem .5rem 0 0;
      bottom: -.2rem; }
    .navigation--active .navigation__link-box li:hover::before {
      transform: scale(0.87); }
  .navigation__phone {
    display: none; }

@media only screen and (max-width: 88em) {
  .navigation {
    background-color: var(--color-pure-white);
    margin: 0;
    padding: 2rem;
    height: 7rem;
    align-items: center;
    overflow: visible; }
    .navigation__logo-box {
      flex: 0 1 9rem;
      background-color: transparent; }
      .navigation__logo-box a:link, .navigation__logo-box a:visited {
        justify-content: start; }
      .navigation__logo-box svg {
        width: 5rem;
        height: 5rem; }
    .navigation__link-box {
      position: fixed;
      top: 0;
      right: -100%;
      padding: .8rem 2rem;
      visibility: hidden;
      transition: all .3s;
      border-radius: 2.7rem 0 0 2.7rem;
      display: block;
      box-shadow: var(--shadow);
      height: 100vh;
      width: 50%;
      z-index: 51000; }
    .navigation__links {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: stretch;
      height: 100%; }
    .navigation__link-box--active {
      visibility: visible;
      right: 0; }
    .navigation__link-box .navigation__links {
      display: grid;
      grid-template-rows: repeat(5, 8rem);
      align-content: center;
      align-items: center;
      justify-content: start; }
      .navigation__link-box .navigation__links li::before {
        display: none; }
      .navigation__link-box .navigation__links li:last-of-type {
        left: 2.5rem; }
      .navigation__link-box .navigation__links li:last-of-type button::after {
        position: absolute;
        content: attr(title);
        top: calc(50% - 1.3rem);
        right: -8rem;
        font-size: 1.9rem;
        font-family: "Dosis", sans-serif;
        color: var(--color-secondary); }
      .navigation__link-box .navigation__links svg {
        width: 4rem;
        height: 4rem;
        margin: 1rem 1rem; }
    .navigation__current::after {
      top: -40%;
      left: -2.5rem;
      border-radius: 0.9rem;
      height: 180%;
      width: .7rem; }
    .navigation__cover--active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: var(--color-black);
      z-index: 50000;
      opacity: .1;
      transition: all .3s;
      visibility: visible; }
    .navigation__button {
      z-index: 51003;
      border: none;
      background-color: transparent;
      display: grid;
      align-items: center;
      align-content: space-between;
      width: 3.5rem;
      height: 3.5rem; }
      .navigation__button > * {
        width: 100%;
        height: .5rem;
        background-color: var(--color-black);
        transition: all .3s;
        border-radius: 5rem; }
    .navigation__button--active div:first-of-type {
      transform-origin: left;
      transform: rotate(45deg) scaleX(1.215); }
    .navigation__button--active div:nth-of-type(2) {
      opacity: 0; }
    .navigation__button--active div:last-of-type {
      transform-origin: left;
      transform: rotate(-45deg) scaleX(1.215); }
    .navigation__phone-menu {
      display: grid;
      grid-template-columns: 1fr min-content;
      grid-column-gap: 1.3rem;
      justify-items: end;
      align-items: center; }
    .navigation__page-name {
      display: block;
      font-size: 1.9rem;
      text-transform: uppercase;
      letter-spacing: .1rem;
      color: var(--color-black);
      font-weight: 600; } }

@media only screen and (max-width: 50em) {
  .navigation__links a:link {
    font-size: 2.7rem; }
  .navigation__link-box {
    width: 80%; } }

.heading-1 {
  padding-left: .7rem;
  color: var(--color-black-light);
  font-size: 5.2rem; }

.heading-2 {
  font-size: 4.4rem;
  font-family: "Dosis", sans-serif;
  text-transform: uppercase;
  line-height: 100%;
  padding-bottom: 2.1rem;
  letter-spacing: .1rem;
  color: var(--color-black-light); }
  @media only screen and (max-width: 34.375em) {
    .heading-2 {
      text-align: center; } }

.heading-3 {
  font-size: 2.6rem;
  font-family: "Dosis", sans-serif;
  text-transform: uppercase;
  letter-spacing: .15rem;
  font-weight: 600;
  color: var(--color-black-light); }

.heading-4 {
  font-size: 2.1rem;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: var(--color-black);
  letter-spacing: .1rem; }

.heading-5 {
  font-size: 2rem;
  font-family: "Dosis", sans-serif;
  font-weight: 700;
  color: var(--color-white-dark);
  text-transform: uppercase;
  letter-spacing: .2rem; }

.heading-6 {
  font-size: 1.8rem;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: var(--color-black);
  letter-spacing: .1rem; }

.paragraph {
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
  max-width: 55%; }
  @media only screen and (max-width: 88em) {
    .paragraph {
      max-width: 72%; } }
  @media only screen and (max-width: 34.375em) {
    .paragraph {
      max-width: 100%; } }

.paragraph--center {
  margin: auto; }
  @media only screen and (max-width: 34.375em) {
    .paragraph--center {
      text-align: left; } }

.paragraph--wide {
  max-width: 80%; }
  @media only screen and (max-width: 34.375em) {
    .paragraph--wide {
      max-width: 100%; } }

.paragraph--max {
  max-width: 100%; }

.main {
  max-width: 190rem;
  padding: 0 5rem;
  margin: auto; }
  @media only screen and (max-width: 54.37em) {
    .main {
      padding: .5rem; } }
  @media only screen and (max-width: 24em) {
    .main {
      padding: 0rem; } }

.footer {
  padding: 0 5rem;
  margin-top: var(--section-space); }
  .footer__container {
    max-width: 180rem;
    margin: auto;
    padding: 7rem 12rem;
    border-radius: 5rem 5rem 0 0;
    background-color: var(--color-white);
    font-family: "Open Sans", sans-serif;
    color: var(--color-black);
    font-size: 1.6rem; }
  .footer__text {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-column-gap: 8rem; }
  .footer ul {
    list-style: none;
    padding-top: 1rem;
    line-height: 1.5; }
    .footer ul li {
      padding-top: .7rem; }
      .footer ul li:not(:last-child) {
        padding-bottom: .5rem; }
  .footer__nav-list a span::after {
    display: inline;
    content: " \2192";
    position: relative;
    visibility: hidden;
    left: 1rem;
    opacity: 0;
    transition: all .2s; }
  .footer__nav-list a:link, .footer__nav-list a:visited {
    text-decoration: none;
    color: inherit; }
  .footer__nav-list a:hover span::after {
    visibility: visible;
    opacity: 1;
    left: 0; }
  .footer__nav-list a:active span::after {
    left: .5rem; }
  .footer__source-code-text, .footer__about-text {
    line-height: 1.5;
    padding-top: 1rem; }
  .footer__source-code-text:not(:last-of-type), .footer__about-text:not(:last-of-type) {
    padding-top: 2rem; }
  .footer__contact {
    display: flex;
    justify-content: end; }
    .footer__contact a:link, .footer__contact a:visited {
      transition: all .2s;
      margin-left: 2rem; }
    .footer__contact a:hover {
      transform: scale(1.03); }
    .footer__contact a:active {
      transform: scale(0.97); }
    .footer__contact svg {
      height: 5rem;
      width: 5rem;
      stroke-width: 0;
      fill: var(--color-secondary);
      stroke: var(--color-secondary);
      stroke-dasharray: 100;
      stroke-dashoffset: 100; }
  .footer__svg--active {
    animation: toNormal 1s ease-out;
    animation-fill-mode: forwards; }

@media only screen and (max-width: 88em) {
  .footer__text {
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    grid-gap: 5rem; }
  .footer__contact {
    padding-top: 5rem;
    justify-content: center; }
    .footer__contact a:link, .footer__contact a:visited {
      margin: 0 1rem; }
  .footer svg {
    height: 7rem;
    width: 7rem; } }

@media only screen and (max-width: 54.37em) {
  .footer {
    padding: 0 .5rem; }
    .footer__container {
      padding: 7rem 1rem; } }

@media only screen and (max-width: 24em) {
  .footer {
    padding: 0; } }

.header {
  margin: 24rem auto 14rem auto; }
  @media only screen and (max-width: 34.375em) {
    .header {
      margin: 15rem auto 10rem auto; } }
  .header__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12rem 0; }
  .header__container svg {
    width: 7.1rem;
    height: 7.1rem;
    margin-right: 1rem;
    fill: var(--color-secondary);
    color: var(--color-secondary); }
  .header * {
    position: relative; }
  .header h1 {
    padding: 0; }

.home-header {
  margin-top: 20rem;
  height: 70vh;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr; }
  .home-header__svgs {
    display: flex;
    justify-content: end;
    align-items: flex-end;
    padding-right: 2.5rem; }
    .home-header__svgs > * {
      padding: 0 2.3rem;
      filter: drop-shadow(var(--shadow));
      position: relative;
      opacity: 0; }
    .home-header__svgs-webpage-1 {
      display: block;
      flex: 0 0 38%; }
    .home-header__svgs-webpage-2 {
      display: block;
      flex: 0 0 14%; }
    .home-header__svgs-webpage-3 {
      display: block;
      flex: 0 0 8%; }
  .home-header__svg-1--active {
    animation: webpageHome3 3.4s;
    animation-fill-mode: forwards; }
  .home-header__svg-2--active {
    animation: webpageHome2 3.1s;
    animation-fill-mode: forwards; }
  .home-header__svg-3--active {
    animation: webpageHome1 2.7s;
    animation-fill-mode: forwards; }
  .home-header__title {
    flex: 0 0 50%; }
  .home-header__heading {
    margin-left: 2.5rem;
    background-image: linear-gradient(var(--color-primary), var(--color-primary-light));
    padding: 13rem 7rem;
    border: 2rem solid var(--color-primary-opacity-05);
    border-right: none;
    color: var(--color-black-dark);
    font-size: 7.5rem;
    font-weight: 400;
    border-radius: 5rem 0 0 5rem;
    letter-spacing: .2rem;
    transition: all .3s; }
    .home-header__heading * {
      position: relative; }
    .home-header__heading-first {
      font-weight: 600; }
  .home-header__heading-sub {
    display: inline-block;
    width: auto;
    margin-top: 1.5rem;
    font-size: 4.4rem;
    letter-spacing: .1rem; }
    .home-header__heading-sub span {
      font-weight: 700; }
  .home-header__hide {
    color: #55555522; }
  .home-header__heading-sub--hidden {
    color: #ffffff00; }

@media only screen and (max-width: 88em) {
  .home-header {
    padding-bottom: 10rem;
    height: auto;
    justify-content: center;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr; }
    .home-header__svgs {
      transform: translate(0, 50%);
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      align-self: end;
      padding-right: 0; }
    .home-header__title {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      padding: 5rem;
      padding-bottom: 25rem;
      border-radius: 5rem;
      background-color: var(--color-primary); }
    .home-header__heading {
      background-image: none;
      margin-left: 0;
      background-color: var(--color-primary);
      box-shadow: var(--shadow-light);
      padding: 5rem 10rem;
      border: none;
      color: var(--color-black-dark);
      border-radius: 5rem;
      text-align: center; } }

@media only screen and (max-width: 50em) {
  .home-header {
    padding-bottom: 10rem;
    height: auto;
    justify-content: stretch;
    justify-items: stretch;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0 2rem 10rem; }
    .home-header__title {
      padding-left: 3rem;
      padding-right: 3rem; }
    .home-header__svgs {
      justify-self: center;
      display: grid;
      grid-template-columns: 13fr 5fr 3fr;
      justify-content: center;
      justify-items: center;
      grid-column-gap: 3rem;
      padding: 0 3rem; }
      .home-header__svgs > * {
        padding: 0;
        filter: drop-shadow(var(--shadow));
        position: relative;
        opacity: 0;
        width: 100%; }
    .home-header__heading {
      padding-left: 0;
      padding-right: 0;
      font-size: 5.9rem;
      letter-spacing: .05rem; }
    .home-header__heading-sub {
      font-size: 3.8rem;
      letter-spacing: .05rem; } }

@media only screen and (max-width: 34.375em) {
  .home-header {
    margin-top: 11rem;
    padding-bottom: 10rem;
    height: auto;
    justify-content: stretch;
    justify-items: stretch;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0 1rem 10rem; }
    .home-header__title {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 20rem; }
    .home-header__svgs {
      justify-self: center;
      display: grid;
      grid-template-columns: 13fr 5fr 3fr;
      justify-content: center;
      justify-items: center;
      grid-column-gap: 3rem;
      padding: 0 3rem; }
      .home-header__svgs > * {
        padding: 0;
        filter: drop-shadow(var(--shadow));
        position: relative;
        opacity: 0;
        width: 100%; }
    .home-header__heading {
      padding: 4rem 0;
      font-size: 5.5rem; }
    .home-header__heading-sub {
      font-size: 3.3rem;
      line-height: 1;
      margin-top: 0rem; } }

.edu-tiles {
  margin-bottom: var(--section-space);
  display: grid;
  grid-template-columns: [first-col] 1fr [second-col] 1fr [third-col]; }
  @media only screen and (max-width: 88em) {
    .edu-tiles {
      grid-template-columns: [first-col] 4fr [second-col] 1fr [third-col];
      grid-template-rows: [first-row] min-content [second-row] 1fr [third-row]; } }
  @media only screen and (max-width: 34.375em) {
    .edu-tiles {
      grid-template-columns: 1fr; } }
  .edu-tiles__container {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-column-gap: 5rem;
    grid-column: first-col / second-col; }
    @media only screen and (max-width: 88em) {
      .edu-tiles__container {
        grid-column: 1 / -1; } }
    @media only screen and (max-width: 54.37em) {
      .edu-tiles__container {
        grid-gap: 3rem;
        grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr)); } }
  .edu-tiles__img {
    justify-self: end;
    align-self: end;
    grid-column: second-col / third-col;
    display: block;
    width: 70%; }
    @media only screen and (max-width: 88em) {
      .edu-tiles__img {
        grid-row: first-row / second-row;
        grid-column: second-col / third-col; } }
    @media only screen and (max-width: 34.375em) {
      .edu-tiles__img {
        grid-column: 1 / -1;
        justify-self: center;
        padding-bottom: 3rem;
        width: 30%; } }
  .edu-tiles__tile {
    position: relative;
    border-radius: 2.7rem;
    overflow: hidden;
    box-shadow: var(--shadow);
    display: flex;
    flex-direction: column;
    font-family: "Open Sans", sans-serif;
    color: var(--color-black); }
    .edu-tiles__tile-border {
      background-color: var(--color-secondary);
      width: 1rem;
      height: 100rem;
      position: absolute;
      top: 0;
      left: 0; }
    .edu-tiles__tile-heading {
      position: relative;
      display: grid;
      grid-template-columns: max-content;
      justify-content: center;
      z-index: 20;
      margin: 3rem 4rem 2rem 0;
      padding: 1rem 3rem 1rem 7rem;
      border-radius: 0 0.9rem 0.9rem 0;
      background-color: var(--color-white-dark); }
      @media only screen and (max-width: 54.37em) {
        .edu-tiles__tile-heading {
          margin-right: 3rem; } }
    .edu-tiles__tile-college {
      display: flex;
      align-items: center;
      padding-left: 2rem; }
      .edu-tiles__tile-college svg {
        display: block;
        padding-top: .3rem;
        padding-right: .5rem;
        height: 3.3rem;
        width: 3.3rem;
        fill: blue; }
    .edu-tiles__tile-name {
      font-size: 1.8rem;
      font-weight: 600;
      letter-spacing: .05rem; }
    .edu-tiles__tile-location {
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: .05rem; }
    .edu-tiles__tile-duration {
      padding: 2rem;
      display: flex;
      align-items: center;
      padding-left: 2.3rem; }
      .edu-tiles__tile-duration svg {
        display: block;
        padding-right: .6rem;
        height: 2.8rem;
        width: 2.8rem; }
    .edu-tiles__tile-date {
      font-size: 1.7rem; }
    .edu-tiles__tile-list {
      margin: 2rem 3rem 2.5rem 4.3rem;
      list-style-image: url("/img/svg/list-svg.svg");
      line-height: 1.5;
      font-size: 1.6rem; }
      .edu-tiles__tile-list li {
        padding-bottom: 1rem; }

.learning {
  padding-top: var(--section-space); }
  .learning__container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-content: space-between;
    grid-column-gap: 20rem; }
    @media only screen and (max-width: 88em) {
      .learning__container {
        grid-column-gap: 7rem; } }
    @media only screen and (max-width: 54.37em) {
      .learning__container {
        grid-row-gap: 5rem;
        grid-column-gap: 0;
        grid-template-columns: auto; } }
    @media only screen and (max-width: 34.375em) {
      .learning__container {
        grid-row-gap: 0; } }
    .learning__container > * {
      grid-column: 1 / -1; }
  .learning__img {
    grid-column: span 1;
    display: block;
    align-self: start;
    width: 100%; }
    .learning__img:not(:first-child) {
      justify-self: end; }
    @media only screen and (max-width: 54.37em) {
      .learning__img {
        width: 30%;
        grid-column: span 2;
        grid-row: 1 / 2;
        justify-self: center; }
        .learning__img:not(:first-child) {
          justify-self: center; } }
    @media only screen and (max-width: 54.37em) {
      .learning__img {
        margin-bottom: 3rem; } }
  .learning__heading {
    grid-column: span 1; }
    .learning__heading--centered {
      grid-column: 1 /-1;
      text-align: center; }
  .learning__buttons {
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, max-content); }
    @media only screen and (max-width: 34.375em) {
      .learning__buttons {
        padding-left: 2rem;
        padding-right: 2rem;
        grid-gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        justify-content: center; } }
    .learning__buttons > * {
      margin: 0 1rem 0 0; }
      @media only screen and (max-width: 34.375em) {
        .learning__buttons > * {
          margin: 0;
          justify-content: center; } }
  .learning__spacing {
    margin-bottom: 3rem; }
    @media only screen and (max-width: 29em) {
      .learning__spacing {
        margin-bottom: 6.3rem; } }
  .learning__heading--centered .learning__buttons {
    justify-content: center;
    text-align: center; }

.show-internet-mail {
  height: 100%;
  width: 100%;
  background-color: var(--color-pure-white);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  display: grid;
  grid-template-rows: 2fr 3fr 10rem;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.4);
  transition: all .3s; }
  .show-internet-mail__button {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    width: 5.8rem;
    height: 5.8rem; }
    .show-internet-mail__button svg {
      position: relative;
      margin-bottom: 0;
      display: block;
      width: 4.5rem;
      height: 4.5rem; }
  .show-internet-mail p {
    font-size: 2.2rem;
    font-family: "Dosis", sans-serif;
    letter-spacing: .1rem; }
    .show-internet-mail p svg {
      fill: var(--color-black);
      position: relative;
      top: .15rem;
      margin: 0 .2rem;
      height: 2rem;
      width: 2rem;
      display: inline-block;
      margin-bottom: 0; }
  .show-internet-mail img {
    display: inline-block;
    height: 2rem;
    width: 2rem; }
  .show-internet-mail--active {
    opacity: 1;
    visibility: visible;
    transform: scale(1); }

.hi-there {
  display: grid;
  justify-content: center;
  text-align: center;
  margin-top: 0 !important;
  padding-bottom: var(--section-space); }
  .hi-there__text {
    background-image: linear-gradient(to right bottom, #FFF600, #D8CB00);
    padding: 3rem 6.5rem;
    margin: auto;
    text-transform: uppercase;
    font-size: 5.7rem;
    font-weight: 600;
    border-radius: 2.7rem;
    transform: skewX(-10deg);
    margin-top: 5rem;
    margin-bottom: 5rem; }
    .hi-there__text p {
      color: var(--color-black);
      transform: skewX(10deg); }
  .body--dark-mode .hi-there__text {
    opacity: .9; }
    .body--dark-mode .hi-there__text p {
      color: var(--color-pure-white); }
  .hi-there__img {
    width: 100%; }
    @media only screen and (max-width: 34.375em) {
      .hi-there__img {
        justify-self: center;
        width: 75%; } }
  .hi-there img.hi-there__img {
    display: none; }
  @media (prefers-reduced-motion) {
    .hi-there object.hi-there__img {
      display: none; }
    .hi-there img.hi-there__img {
      display: block; } }

.four-o-four {
  display: grid;
  grid-row-gap: 2rem;
  align-items: center;
  align-content: start;
  padding: 15rem 0;
  background-image: linear-gradient(to right bottom, var(--color-pure-white), var(--color-gray-light));
  height: 100vh; }
  .four-o-four__svg {
    max-width: 50vh;
    margin: 3rem auto 0;
    display: inline-block;
    filter: drop-shadow(var(--shadow)); }
    @media only screen and (max-width: 34.375em) {
      .four-o-four__svg {
        width: 80%; } }
